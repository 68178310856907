import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

import {animation} from "../../utils/index";

const problems={
    "Students perform poorly in the campus placements":
        "Even after the college puts in effort to bring recruiters, most students fail to clear the interview and end up wasting that effort."
    ,
   "Training & workshops are not working":"More than 80% students don't pay attention during training, which means placement rate doesn't increase.",
    "Not enough 1:1 attention":
        "Students need at least 10-15 mock interviews to be confident and increase their chances. How will you deliver that?"
}

const useStyles = makeStyles((theme) => ({
    container:{
        minHeight:"fit-content",
        width:"100dvw",
        display:"flex",
        borderBottom:`2px solid ${theme.palette.divider}`,
        color:"#FD5555",
        [theme.breakpoints.down('700')]:{
            flexWrap:"wrap",
        },
    },
    left:{
        width:"30%",
        backgroundColor:theme.palette.danger['clr-100'],
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        textAlign:"center",
        borderRight:`2px solid ${theme.palette.divider}`,
        padding:theme.spacing(0,37.5),
        fontSize:"80px",
        fontFamily:theme.typography['h1-xl-regular'].fontFamily,
        boxSizing:"border-box",
        [theme.breakpoints.down('xl')]:{
            width:"40%"
        },
        [theme.breakpoints.down('lg')]:{
            padding: theme.spacing(0,15)
          },
        [theme.breakpoints.down('md')]:{
            padding: theme.spacing(0,10)
          },
        [theme.breakpoints.down('700')]:{
            width:"100%",
            padding:theme.spacing(10),
            height:"325px",
            borderRight:0,
            textAlign:"left",
        },
        [theme.breakpoints.down('sm')]:{
          padding: theme.spacing(0,5)
        }
    },
    text:{
        fontWeight: 500,
        textAlign: "left",
        fontSize:"80px",
        lineHeight: "120%",
        fontFamily: theme.typography['h1-xl-regular'].fontFamily,
        [theme.breakpoints.between('md','lg')]:{
            fontSize:"68px",
        }, 
        [theme.breakpoints.down('md')]:{
            fontSize:"57px",
        },
    },
    right:{
        height:"100%",
        width:"70%",
        display:"flex",
        flexDirection:"column",
        gap:theme.spacing(10),
        textAlign:"left",
        padding:theme.spacing(25,37.5,25,25),
        opacity:0,
        transform:"translateX(-20px)",
        transition:"all 500ms ease",
        [theme.breakpoints.between('lg','xl')]:{
            gap:theme.spacing(8),
            width:"60%"
        },
        [theme.breakpoints.between('md','lg')]:{
            padding:theme.spacing(10,15,10,10),
            gap:theme.spacing(6),
        },
        [theme.breakpoints.between('700','md')]:{
            padding:theme.spacing(10),
            gap:theme.spacing(6),
        },
        [theme.breakpoints.down('700')]:{
            width:"100%",
            padding:theme.spacing(5,10),
        },
        [theme.breakpoints.down('sm')]:{
          padding: theme.spacing(5)
        },
        borderLeft:0,
        boxSizing:"border-box"
    },
    problemItem:{
        display:"flex",
        flexDirection:"column",
        gap:theme.spacing(2)
    },
    enterFromX:{
        opacity:1,
        transform:"translateX(0px)",
    },
    italic:{
        fontFamily: theme.typography['h1-xl-regular'].fontFamily,
        fontStyle:"italic"
    },
    problemTitle:{
        fontSize: "33px",
        lineHeight: "39.6px",
        letterSpacing: "0.10000000149011612px",
        textAlign: "left"
    },
    problemDescription:{
      fontSize: "19px",
      lineHeight: "23.56px",
      textAlign: "left"
    }
}));


export default function Problems() {
    const classes = useStyles();

    React.useEffect(()=>{
        const handleScroll = () => {
            animation(`.${classes.right}`, `${classes.enterFromX}`,200);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      },[])

    return (
        <Box className={classes.container}>
          <Box className={classes.left}>
            <Typography className={classes.text}>
                We know your {' '}
                <span className={classes.italic}>
                    problems
                </span>
            </Typography>
          </Box>
          <Box className={classes.right}>
            {Object.entries(problems).map(([key, value], index) => (
                <Box key={index} className={classes.problemItem}>
                    <Typography variant="h3-medium" className={classes.problemTitle}>
                        {key}
                    </Typography>
                    <Typography variant="h6-regular" className={classes.problemDescription} 
                    color="neutral.clr-600">
                        {value}
                    </Typography>
                </Box>
            ))}
            </Box>
        </Box>
    );
}