export const SubApplication = {
    "teach-n-train": "teach-n-train",
    "inprep": "inprep"
};

export const InprepFields = {
    "jobRole": [
        {
            _id: "product_manager",
            name: "Product Manager",
            template: process.env.REACT_APP_PRODUCT_MANAGER_TEMPLATE_ID,
        },
        {
            _id: "sales_&_marketing",
            name: "Sales & Marketing",
            template: process.env.REACT_APP_SALES_MARKETING_TEMPLATE_ID,
        },
        {
            _id: "data_analyst",
            name: "Data Analyst",
            template: process.env.REACT_APP_DATA_ANALYST_TEMPLATE_ID,
        },
        {
            _id: "software_engineer",
            name: "Software Engineer",
            template: process.env.REACT_APP_SOFTWARE_ENGINEER_TEMPLATE_ID,
        },
    ],
    "level": [
        { _id: "fresher", name: "Fresher" },
        { _id: "mid_senior", name: "Mid Senior" },
        { _id: "senior", name: "Senior" },
    ],
};

export const TNT_Fields = {
    "domains": [
        {
            _id: 'productManagement',
            name: "Product Management",
            topics: [
                {
                    _id: 'agileProductDevelopment',
                    name: 'Agile Product Development',
                    difficultyLevels: [
                        { _id: 'easy', name: 'Easy', template: '651e5f470aad2ac6dbfba24e' },
                        { _id: 'intermediate', name: 'Intermediate', template: '651e60a40aad2ac6dbfba2da' },
                        { _id: 'advance', name: 'Advance', template: '651e61650aad2ac6dbfba366' }
                    ]
                },
                {
                    _id: 'userCentricDesignAndUserExperience(UX)',
                    name: 'User-Centric Design and User Experience (UX)',
                    difficultyLevels: [
                        { _id: 'easy', name: 'Easy', template: '651e699e0aad2ac6dbfba3f8' },
                        { _id: 'intermediate', name: 'Intermediate', template: '651e6a0b0aad2ac6dbfba484' },
                        { _id: 'advance', name: 'Advance', template: '651e6a8e0aad2ac6dbfba510' }
                    ]
                },
                {
                    _id: 'dataDrivenDecisionMaking',
                    name: 'Data-Driven Decision Making',
                    difficultyLevels: [
                        { _id: 'easy', name: 'Easy', template: '651e6ae20aad2ac6dbfba59c' },
                        { _id: 'intermediate', name: 'Intermediate', template: '651e6b420aad2ac6dbfba62b' },
                        { _id: 'advance', name: 'Advance', template: '651e6bb20aad2ac6dbfba6b7' }
                    ]
                },
                {
                    _id: 'productStrategyAndRoadmapping',
                    name: 'Product Strategy and Roadmapping',
                    difficultyLevels: [
                        { _id: 'easy', name: 'Easy', template: '651e6c290aad2ac6dbfba830' },
                        { _id: 'intermediate', name: 'Intermediate', template: '651e6cea0aad2ac6dbfba90b' },
                        { _id: 'advance', name: 'Advance', template: '651e6d520aad2ac6dbfba99d' }
                    ]
                },
                {
                    _id: 'stakeholderManagementAndCommunication',
                    name: 'Stakeholder Management and Communication',
                    difficultyLevels: [
                        { _id: 'easy', name: 'Easy', template: '651e6e060aad2ac6dbfbaa29' },
                        { _id: 'intermediate', name: 'Intermediate', template: '651e6eac0aad2ac6dbfbaabb' },
                        { _id: 'advance', name: 'Advance', template: '651e6f240aad2ac6dbfbab47' }
                    ]
                },
            ]
        },
        {
            _id: 'marketing',
            name: "Marketing",
            topics: [
                {
                    _id: 'digitalMarketing',
                    name: 'Digital Marketing',
                    difficultyLevels: [
                        { _id: 'easy', name: 'Easy', template: '651e9b3c0aad2ac6dbfbbe14' },
                        { _id: 'intermediate', name: 'Intermediate', template: '651e9b8d0aad2ac6dbfbbe96' },
                        { _id: 'advance', name: 'Advance', template: '651e9bee0aad2ac6dbfbbf1e' }
                    ]
                },
                {
                    _id: 'socialMediaMarketing',
                    name: 'Social Media Marketing',
                    difficultyLevels: [
                        { _id: 'easy', name: 'Easy', template: '651e9d040aad2ac6dbfbbfa9' },
                        { _id: 'intermediate', name: 'Intermediate', template: '651e9d9e0aad2ac6dbfbc0bf' },
                        { _id: 'advance', name: 'Advance', template: '651e9df00aad2ac6dbfbc141' }
                    ]
                },
                {
                    _id: 'contentMarketing',
                    name: 'Content Marketing',
                    difficultyLevels: [
                        { _id: 'easy', name: 'Easy', template: '651e9e410aad2ac6dbfbc1c9' },
                        { _id: 'intermediate', name: 'Intermediate', template: '651e9e990aad2ac6dbfbc24b' },
                        { _id: 'advance', name: 'Advance', template: '651e9edd0aad2ac6dbfbc2cd' }
                    ]
                },
                {
                    _id: 'brandingAndIdentity',
                    name: 'Branding and Identity',
                    difficultyLevels: [
                        { _id: 'easy', name: 'Easy', template: '651e9fa90aad2ac6dbfbc34f' },
                        { _id: 'intermediate', name: 'Intermediate', template: '651ea3750aad2ac6dbfbc456' },
                        { _id: 'advance', name: 'Advance', template: '651ea3ca0aad2ac6dbfbc4db' }
                    ]
                },
                {
                    _id: 'marketResearchAndAnalysis',
                    name: 'Market Research and Analysis',
                    difficultyLevels: [
                        { _id: 'easy', name: 'Easy', template: '651ea44d0aad2ac6dbfbc55d' },
                        { _id: 'intermediate', name: 'Intermediate', template: '651ea4860aad2ac6dbfbc5df' },
                        { _id: 'advance', name: 'Advance', template: '651ea59c0aad2ac6dbfbc6b4' }
                    ]
                },
            ]
        },
        {
            _id: 'dataAnalytics',
            name: "Data Analytics",
            topics: [
                {
                    _id: 'machineLearningAndArtificialIntelligence',
                    name: 'Machine Learning and Artificial Intelligence',
                    difficultyLevels: [
                        { _id: 'easy', name: 'Easy', template: '651e70670aad2ac6dbfbabfd' },
                        { _id: 'intermediate', name: 'Intermediate', template: '651e70ee0aad2ac6dbfbaccf' },
                        { _id: 'advance', name: 'Advance', template: '651e71460aad2ac6dbfbad51' }
                    ]
                },
                {
                    _id: 'bigDataAnalytics',
                    name: 'Big Data Analytics',
                    difficultyLevels: [
                        { _id: 'easy', name: 'Easy', template: '651e71b10aad2ac6dbfbadd6' },
                        { _id: 'intermediate', name: 'Intermediate', template: '651e71f80aad2ac6dbfbae58' },
                        { _id: 'advance', name: 'Advance', template: '651e723d0aad2ac6dbfbaeda' }
                    ]
                },
                {
                    _id: 'dataVisualization',
                    name: 'Data Visualization',
                    difficultyLevels: [
                        { _id: 'easy', name: 'Easy', template: '651e72960aad2ac6dbfbaf5c' },
                        { _id: 'intermediate', name: 'Intermediate', template: '651e73070aad2ac6dbfbb02e' },
                        { _id: 'advance', name: 'Advance', template: '651e73610aad2ac6dbfbb0b3' }
                    ]
                },
                {
                    _id: 'dataMining',
                    name: 'Data Mining',
                    difficultyLevels: [
                        { _id: 'easy', name: 'Easy', template: '651e73bc0aad2ac6dbfbb138' },
                        { _id: 'intermediate', name: 'Intermediate', template: '651e73fe0aad2ac6dbfbb1bd' },
                        { _id: 'advance', name: 'Advance', template: '651e74470aad2ac6dbfbb245' }
                    ]
                },
                {
                    _id: 'dataPrivacyAndEthics',
                    name: 'Data Privacy and Ethics',
                    difficultyLevels: [
                        { _id: 'easy', name: 'Easy', template: '651e74a30aad2ac6dbfbb2ca' },
                        { _id: 'intermediate', name: 'Intermediate', template: '651e75020aad2ac6dbfbb34c' },
                        { _id: 'advance', name: 'Advance', template: '651e754a0aad2ac6dbfbb3d4' }
                    ]
                },
            ]
        },
        {
            _id: 'softwareEngineering',
            name: "Software Engineering",
            topics: [
                {
                    _id: 'mobileApplicationDevelopment',
                    name: 'Mobile Application Development',
                    difficultyLevels: [
                        { _id: 'easy', name: 'Easy', template: '651e89ba0aad2ac6dbfbb451' },
                        { _id: 'intermediate', name: 'Intermediate', template: '651e8a050aad2ac6dbfbb4d6' },
                        { _id: 'advance', name: 'Advance', template: '651e8a470aad2ac6dbfbb558' }
                    ]
                },
                {
                    _id: 'devOps',
                    name: 'DevOps',
                    difficultyLevels: [
                        { _id: 'easy', name: 'Easy', template: '651e8a9d0aad2ac6dbfbb5dd' },
                        { _id: 'intermediate', name: 'Intermediate', template: '651e8ad60aad2ac6dbfbb65f' },
                        { _id: 'advance', name: 'Advance', template: '651e8b1c0aad2ac6dbfbb731' }
                    ]
                },
                {
                    _id: 'cloudComputing',
                    name: 'Cloud Computing',
                    difficultyLevels: [
                        { _id: 'easy', name: 'Easy', template: '651e8b5e0aad2ac6dbfbb7b3' },
                        { _id: 'intermediate', name: 'Intermediate', template: '651e8b960aad2ac6dbfbb835' },
                        { _id: 'advance', name: 'Advance', template: '651e8bf60aad2ac6dbfbb8ba' }
                    ]
                },
                {
                    _id: 'cybersecurity',
                    name: 'Cybersecurity',
                    difficultyLevels: [
                        { _id: 'easy', name: 'Easy', template: '651e8c3e0aad2ac6dbfbb989' },
                        { _id: 'intermediate', name: 'Intermediate', template: '651e8c920aad2ac6dbfbba11' },
                        { _id: 'advance', name: 'Advance', template: '651e8ce30aad2ac6dbfbba99' }
                    ]
                },
                {
                    _id: 'agileSoftwareDevelopment',
                    name: 'Agile Software Development',
                    difficultyLevels: [
                        { _id: 'easy', name: 'Easy', template: '651e8d460aad2ac6dbfbbbc6' },
                        { _id: 'intermediate', name: 'Intermediate', template: '651e8dc60aad2ac6dbfbbd0c' },
                        { _id: 'advance', name: 'Advance', template: '651e8e190aad2ac6dbfbbd8e' }
                    ]
                },
            ]
        },
    ]
};

export const Team = [
    {
      url: 'https://assets.languify.in/images/lokap.png',
      name: 'Lokap Sahu',
      role: 'CEO & Co-Founder IITB',
      linkedinUrl: 'https://www.linkedin.com/in/lokap-sahu/',
    },
    {
      url: 'https://assets.languify.in/images/mohak.png',
      name: 'Mohak Sahu',
      role: 'CTO AI IITB',
      linkedinUrl: 'https://www.linkedin.com/in/mohaksahu/'
    },
    {
      url: 'https://assets.languify.in/images/shivam.png',
      name: 'Shivam Sahu',
      role: 'CDO',
      linkedinUrl: 'https://www.linkedin.com/in/shivam-sahu-ba7349175/'
    },
    {
      url: 'https://assets.languify.in/images/asif.png',
      name: 'Asif Hashmi',
      role: 'Product Manager',
      linkedinUrl: 'https://www.linkedin.com/in/asifhashmi99/'
    },
    {
      url: 'https://assets.languify.in/images/aayush.png',
      name: 'Aayush Priyank',
      role: 'Head of Growth',
      linkedinUrl: 'https://www.linkedin.com/in/aayush-priyank/'
    },
    {
      url: 'https://assets.languify.in/images/mahmood_alam.png',
      name: 'Mahmood Alam',
      role: "Customer Success Manager",
      linkedinUrl: 'https://www.linkedin.com/in/mahmood-alam-syed-850351a0/'
    },
    {
      url: 'https://assets.languify.in/images/prince.png',
      name: 'Prince Patel',
      role: 'SDE-1',
      linkedinUrl: "https://www.linkedin.com/in/prince-patel-bb6b32194/"
    },
    {
      url: 'https://assets.languify.in/images/mohit.png',
      name: 'Mohit Khole',
      role: 'Product Designer',
      linkedinUrl: 'https://www.linkedin.com/in/mohit-khole-123988188/'
    },
    {
      url: 'https://assets.languify.in/images/manasi.png',
      name: 'Manasi Rote',
      role: 'Partnerships Associate',
      linkedinUrl: 'https://www.linkedin.com/in/manasi-rote/'
    },
    {
      url: 'https://assets.languify.in/images/sagar.png',
      name: 'Sagar Dewale ',
      role: 'Product Management',
      linkedinUrl: 'https://www.linkedin.com/in/sagardewale/'
    },
    {
      url: 'https://assets.languify.in/images/poorva.png',
      name: 'Poorva Gupta',
      role: 'Product Management',
      linkedinUrl: 'https://www.linkedin.com/in/poorvagupta18/'
    },
    {
      url: 'https://assets.languify.in/images/chinmay.png',
      name: 'Chinmay Mehta',
      role: 'Growth',
      linkedinUrl: 'https://www.linkedin.com/in/m-chinmay/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'
    },
    {
      url: 'https://assets.languify.in/images/Shashirekha+M.png',
      name: 'Shashirekha M',
      role: 'Entrepreneur in Residence',
      linkedinUrl: 'https://www.linkedin.com/in/shashirekha-m-3653002a8/'
    },
    {
      url: 'https://assets.languify.in/images/Dhruv.png',
      name: 'Dhruv Patel',
      role: 'Growth',
      linkedinUrl: 'https://www.linkedin.com/in/dhruv-patel-8a5a74222//'
    },
    {
      url: 'https://assets.languify.in/images/Pragati.png',
      name: 'Pragati Azad',
      role: 'Full Stack Development',
      linkedinUrl: 'https://www.linkedin.com/in/pragati-azad-aaa36b200/'
    },
    {
      url: 'https://assets.languify.in/images/Anirudh.png',
      name: 'Aniruddh Udayan',
      role: 'Full Stack Development',
      linkedinUrl: 'https://www.linkedin.com/in/aniruddh-udayan-997534226/'
    },
    {
      url: 'https://assets.languify.in/images/arunanshu.png',
      name: 'Arunanshu Sinha',
      role: 'Product Management',
      linkedinUrl: 'https://www.linkedin.com/in/arunanshu-sinha/'
    },
];
  
export const Mentor = [
    {
      url: 'https://assets.languify.in/images/mentors/SachinPranJape.png',
      name: 'Sachin Paranjape',
      role: 'IITR Product Advisor'
    },
    {
      url: 'https://assets.languify.in/images/mentors/Parag.png',
      name: 'Parag Bharagav',
      role: 'Professor, IITB'
    },
    {
      url: 'https://assets.languify.in/images/mentors/Anuj.png',
      name: 'Anuj Sharma',
      role: 'CEO, Alsisar'
    },
    {
      url: 'https://assets.languify.in/images/mentors/Ranjit.png',
      name: 'Ranjeet Gakhare',
      role: 'Associate Director, FlowBiz'
    },
    {
      url: 'https://assets.languify.in/images/mentors/Sachin.png',
      name: 'Sachin Bhat',
      role: 'Product & Strategy'
    },
    {
      url: 'https://assets.languify.in/images/mentors/Jasnit.png',
      name: 'Prof. Jasnit',
      role: 'Content Development'
    },
    {
      url: 'https://assets.languify.in/images/mentors/Labdi.png',
      name: 'Labdhi Chopda',
      role: 'Growth & Strategy, IITD'
    }
]

export const Categories = {
    "FLUENCY": "Fluency",
    "WORD_CHOICE": "Vocabulary",
    "DELIVERY": "Vocal Delivery",
    "CONFIDENCE": "Confidence",
    "SIMILARITY": "Similarity",
    "RELEVANCE": "Relevance",
    "LOGIC_FLOW": "Logic flow",
};
