import React from "react";
import styled from '@emotion/styled';
import MUIButton from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CopiedIcon from '@mui/icons-material/CheckCircle';
import CopyIcon from '@mui/icons-material/ContentCopyOutlined';
import { copyToClipboard } from 'utils';

export function CopyButton({
  content, title = 'Copy', textRef, iconProps = {}, ...props
}) {
  const [copied, setCopied] = React.useState(false);

  const handleCopy = () => {
    if (copied) return;

    copyToClipboard(content, textRef);

    setCopied(true);

    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <IconButton onClick={handleCopy} title={title} {...props}>
      {copied ? <CopiedIcon color='success' /> : <CopyIcon {...iconProps} />}
    </IconButton>
  )
}

export function LightButton(props) {
  const variant = props.variant;

  return (
    <MUIButton {...props} sx={{
      width: `${props.width}`,
      ...(variant === 'contained' ? { backgroundColor: "subprimary.main", color: "white.main" } : {}),
      ...(variant === 'outlined' ? { borderColor: "#2DABFF", color: "subprimary.main", } : {}),
      ...(variant === 'text' ? { color: "subprimary.main" } : {}),
      borderRadius: `${props.borderRadius}`,
      cursor: 'pointer',
      fontFamily: 'Lato',
      fontWeight: '600',
      fontStyle: 'normal',
      fontSize: 14,
      textTransform: 'capitalize',
      margin: '4px',
      '&:hover': {
        backgroundColor: variant === 'contained' ? '#2DABFFdd' : 'transparent',
      },
      ...props.sx
    }}

    />
  );
}

export function PrimaryButton(props) {

  return (
    <MUIButton {...props}
      variant="contained"
      sx={{
        width: `${props.width}`,
        borderRadius: `${props.borderRadius}`,
        background: 'linear-gradient(180deg, #2DABFF 0%, #02569D 100%)',
        cursor: 'pointer',
        color: "#ffffff",
        fontFamily: 'Lato',
        fontWeight: '600',
        fontStyle: 'normal',
        fontSize: 14,
        textTransform: 'capitalize',
        // margin: '4px',
        ...props.sx
      }}
    />
  );
}

export function SecondaryButton(props) {

  return (
    <MUIButton {...props}
      variant="contained"
      sx={{
        width: `${props.width}`,
        borderRadius: `${props.borderRadius}`,
        background: 'linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%)',
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)',
        cursor: 'pointer',
        color: "primary.main",
        fontFamily: 'Lato',
        fontWeight: '600',
        fontStyle: 'normal',
        fontSize: 14,
        textTransform: 'capitalize',
        margin: '4px',
        ...props.sx
      }}
    />
  );
}

export const Button = styled((props) => (
  <MUIButton {...props} />))(theme => ({
    textTransform: 'none',
    padding: '4px 48px',
    fontSize: 13,
    margin: '4px',
    fontFamily: 'Lato',
  }));

export default Button;