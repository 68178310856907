import React from 'react';
import AppShell from 'components/AppShell';
import Main from './Main';
import Demo from 'components/Demo';
import ProductBreakdown from './ProductBreakdown';
import Faq from 'components/Faq';
import ClientsSection from 'components/ClientsSection';
import { useLocation } from 'react-router-dom';
import TestimonialSection from 'components/TestimonialSection';
import SoftwareSection from 'components/SoftwareSection';
import { ProductVision } from 'screens/TeachAndTrain/ProductVision';

function InPrep() {
    const elementToScrollRef = React.useRef(null);
    const location = useLocation();

    const showDemo = React.useCallback(() => {
        if (elementToScrollRef.current) {
            elementToScrollRef.current.scrollIntoView({ behavior: "smooth",block: "end", inline: "nearest" });
        }
    }, []);

    React.useEffect(() => {
        if (location.hash === "#demo") showDemo();
    }, []);

    return (
        <AppShell>
            <Main onExperienceLive={showDemo} />
            <ClientsSection/>
            <ProductVision
                link={"https://assets.languify.in/video/home_video.mp4"}
                thumbnail={"https://assets.languify.in/images/home_thumbnail.webp"}
            />
            <Demo elementToScrollRef={elementToScrollRef} />
            <ProductBreakdown onExperienceLive={showDemo} />
            <TestimonialSection/>
            <SoftwareSection/>
            <Faq />
        </AppShell>
    );
}

export default InPrep;