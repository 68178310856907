import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(2.5),
    width: 'fit-content',
  },
  hiddenItem: {
    opacity: 0,
  },
  item: {
    width: '13px',
    height: '13px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  row: {
    marginBottom: '10px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
}));

//hiddenRowIndex: Index of the row where elements need to be hidden.
//hiddenColIndexStart: Column index from where elements need to be hidden.
//hiddenColIndexEnd: Column index until where elements need to be hidden.

const PlusTiles = ({
  color,
  hiddenRowIndex = -1,
  hiddenColIndexStart = -1,
  hiddenColIndexEnd = -1,
  numItems = 5,
  numRows = 3,
  fontSize = '25px',
}) => {
  const classes = useStyles();
  const items = Array(numItems).fill('+');
  const rows = Array(numRows).fill(null);

  return (
    <>
      {rows.map((_, rowIndex) => (
        <Box
          key={rowIndex}
          className={`${classes.container} ${classes.row}`}
          style={{ color: color }}
        >
          {items.map((item, colIndex) => (
            <Typography
              key={colIndex}
              className={`${classes.item} 
                ${rowIndex === hiddenRowIndex && colIndex >= hiddenColIndexStart &&
                colIndex <= hiddenColIndexEnd ? classes.hiddenItem : ''}`}
              style={{ fontSize: fontSize }}
            >
              {item}
            </Typography>
          ))}
        </Box>
      ))}
    </>
  );
};

export { PlusTiles };
