import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography, Fade , Divider , Button , MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const useStyles = makeStyles(theme => ({
    lowerCase: {
        textTransform: 'none',
        ...theme.typography['h6-semiBold'],
        color: theme.palette.shades['clr-black-900'],
    },
    buttonHover: {
        padding: theme.spacing(0),
        minWidth: "110px",
        '&:hover': {
            backgroundColor: 'transparent',
            '& .hoverableText': {
                color: theme.palette.neutral['clr-600'],
            },
        },
        '& .hoverableText': {
            transition: 'color 0.3s ease',
        },
    },
    downArrow: {
        color: theme.palette.shades['clr-black-900'],
    },
    divider: {
        margin: theme.spacing(0),
        borderColor: '#BFCAD9',
        '&.MuiDivider-root': {
            margin: theme.spacing(0),
            backgroundColor: theme.palette.shades['clr-black-900'],
        },
    },
    selectedMenuItem: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: `${theme.palette.shades['clr-white-900']} !important`,
    },
    selectedText: {
        color: `${theme.palette.shades['clr-white-900']} !important`,
    },
    menuContainer: {
        position: 'absolute',
        minWidth: '300px',
        backgroundColor: theme.palette.shades['clr-white-900'],
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.16)',
        borderRadius: 4,
        zIndex: 1000,
        marginTop:theme.spacing(3),
        '& .MuiMenu-list': {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(0, 0),
        },
    },
    menuItem: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        '&:hover': {
            backgroundColor: theme.palette.primary['clr-50'],
        },
    },
    link: {
        textDecoration: 'none',
        width: '100%',
        color: 'inherit',
        '&:hover': {
            textDecoration: 'none',
        },
    },
}));

const HeaderMenu = ({ items, title }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const classes = useStyles();

    const navigate = useNavigate();

    const handleMouseEnter = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMouseLeave = (event) => {
        const { relatedTarget } = event;
        if (!event.currentTarget.contains(relatedTarget)) {
            setAnchorEl(null);
        }
    };

    const handleMenuItemClick = (index , link) => {
        setSelectedIndex(index);
        setAnchorEl(null);
        navigate(link);
    };

    const handleMenuMouseEnter = () => {
        setAnchorEl(true);
    };

    const handleMenuMouseLeave = (event) => {
        const { relatedTarget } = event;
        if (!event.currentTarget.contains(relatedTarget)) {
            setAnchorEl(null);
        }
    };

    return (
        <Box position="relative" display="inline-block">
            <Button
                id="basic-button"
                aria-controls={anchorEl ? 'basic-menu' : undefined}
                aria-expanded={Boolean(anchorEl)}
                aria-haspopup="true"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className={classes.buttonHover}
            >
                <Typography  className={`${classes.lowerCase} hoverableText`}>
                    {title}
                </Typography>
                <KeyboardArrowDownIcon className={`${classes.downArrow} hoverableText`} />
            </Button>
            <Fade in={Boolean(anchorEl)} timeout={300}>
                <Box
                    id="basic-menu"
                    className={classes.menuContainer}
                    onMouseEnter={handleMenuMouseEnter}
                    onMouseLeave={handleMenuMouseLeave}
                >
                    {items.map((item, index) => (
                        <React.Fragment key={item.id}>
                                <MenuItem
                                    onClick={() => handleMenuItemClick(index , item.link)}
                                    className={`${selectedIndex === index ? 
                                    classes.selectedMenuItem : ''} ${classes.menuItem}`}
                                >
                                    <Box>
                                        <Typography
                                            variant='h6-medium'
                                            className={selectedIndex === index ? 
                                            classes.selectedText : ''}
                                        >
                                            {item.label}
                                        </Typography>
                                    </Box>
                                </MenuItem>
                            {index < items.length - 1 && <Divider className={classes.divider} />}
                        </React.Fragment>
                    ))}
                </Box>
            </Fade>
        </Box>
    );
};

export default HeaderMenu;
