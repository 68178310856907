import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  chipRoot: {
    borderRadius: theme.spacing(5),
    padding: theme.spacing(2, 4),
    backgroundColor: theme.palette.secondary['clr-100'],
    width: 'fit-content',
    display: 'flex',
    gap: theme.spacing(1),
  },
  chipContainer: {
    display: 'flex',
    minWidth: 'fit-content',
    height: '36px',
    padding: theme.spacing(2, 4),
    borderRadius: '20px',
    textTransform: 'none',
  },
  inactiveChip: {
    color: theme.palette.neutral['clr-600'],
    border: `1px solid`,
    borderColor: theme.palette.neutral['clr-600'],
    '&:hover': {
      backgroundColor: theme.palette.primary['clr-50'],
    }
  },
  activeChip: {
    color: theme.palette.shades['clr-white-900'],
    backgroundColor: theme.palette.primary['clr-500'],
    '&:hover': {
      backgroundColor: theme.palette.primary['clr-500'],
    }
  }
}));

const Chip = ({ content, color, bgColor, startIcon = null, endIcon = null, sx = {}, ...props }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.chipRoot}
      style={{ backgroundColor: bgColor, color: color, ...sx }}
      {...props}
    >
      {startIcon && startIcon}
      <Typography variant='body01-bold'>
        {content}
      </Typography>
      {endIcon && endIcon}
    </Box>
  )
}
export function ClickableChip({ label, isActive, onClick = () => { } }, ...props) {
  const classes = useStyles();
  return (
    <Button
      className={`${classes.chipContainer} ${isActive ?
        classes.activeChip : classes.inactiveChip}`}
      onClick={onClick}
      {...props}
    >
      <Typography variant='body01-bold'>{label}</Typography>
    </Button>
  );
}


export default Chip;
