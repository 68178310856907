import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import WaveEffect from './WaveEffect';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  stageBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  successImage: {
    marginTop: theme.spacing(15), 
  },
  loadingImage: {
    marginTop: theme.spacing(15),
  },
  waveEffect: {
    marginTop: theme.spacing(50),
  },
}));

const AnalysingEffect = () => {
  const classes = useStyles();
  const [stage, setStage] = useState(1);

  useEffect(() => {
    const timers = [
      setTimeout(() => setStage(2), 2000), 
      setTimeout(() => setStage(3), 4000),
    ];

    return () => timers.forEach(clearTimeout);
  }, []);

  return (
    <Box className={classes.container}>
      {stage === 1 && (
        <Box className={classes.stageBox}>
          <Typography variant="h2-medium" color="neutral.clr-600">
            Analyzing your answer
          </Typography>
          <img
            src="https://languify-assets.s3.ap-south-1.amazonaws.com/Loading.png"
            alt="Loading"
            className={classes.loadingImage}
          />
        </Box>
      )}
      {stage === 2 && (
        <Box className={classes.stageBox}>
          <Typography variant="h2-medium" color="success.clr-500">
            Analyzed Successfully
          </Typography>
          <img
            src="https://languify-assets.s3.ap-south-1.amazonaws.com/Tick.png"
            alt="Success"
            className={classes.successImage}
          />
        </Box>
      )}
      {stage === 3 && (
        <Box className={classes.stageBox}>
          <Typography variant="h2-medium" color="success.clr-500">
            Nicely done, brewing your content & communication report
          </Typography>
          <Box className={classes.waveEffect}>
            <WaveEffect />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AnalysingEffect;
