import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grow from "@mui/material/Grow";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import CloseIcon from '@mui/icons-material/Close';
import ListeningTag from "components/ListeningTag";

import { makeStyles, styled, useTheme } from "@mui/styles";
import { askPermission, checkPermission } from "utils/permissions";
import { generateDemoQuestion } from "services/interviewQuestionService";
import { analyseAnswer } from "services/analysisService";
import { finishInterviewAttempt } from "services/interviewAttemptService";
import { useSnackbar } from "contexts/SnackbarProvider";
import SpeechAnimation from "components/SpeechAnimation";
import SpeechRecorder from "languify-speech-recorder";
import Session from "utils/Session";
import synthesizeSpeech from "utils/synthesizeSpeech";
import AnalysingEffect from "components/AnalysingEffect"
import WaveEffect from "components/WaveEffect";
import CustomButton from "components/CustomButton";

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            width: '946px',
        },
    },
    dialogContent: {
        position: 'relative',
        overflow: 'hidden',
    },
    waveBox: {
        height: "100%",
        width: '100%',
        marginTop: theme.spacing(20),
        padding: theme.spacing(0,10,0,10),
        boxSizing: 'border-box',
        textAlign: 'center',
    },
    WaveEffectBox: {
        marginTop: theme.spacing(46),
    },
    timer: {
        marginTop: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    timerheading: {
        marginRight: theme.spacing(2)
    },
    textContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(1, 10, 8, 7),
        boxSizing: 'border-box',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 5, 4, 5),
        }
    },
    text: {
        textAlign: 'start',
        marginLeft: theme.spacing(10),
        [theme.breakpoints.down('sm')]: {
            color: "neutral.clr-900",
            marginLeft: theme.spacing(0),
        }
    },
    question: {
        margin: theme.spacing(3, 0),
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        }
    },
    BlueBox: {
        backgroundColor: "#E5F1FF",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(7, 10),
        height: "120px",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            padding: theme.spacing(2, 4),
        },
    },
    LogoBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: theme.spacing(2),
    },
    end: {
        borderRadius: 4,
        border: '1px solid #003E8C',
        padding: theme.spacing(3, 6),
        color: 'primary.clr-500',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: "#E5F1FF",
        },
        '&:disabled': {
            backgroundColor: "#4E5661",
        },
        '&:active': {
            backgroundColor: '#FFFFFF',
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3, 4),
            borderRadius: 4,
        },
    },
    "@keyframes typing": {
        from: { width: 0 },
        to: { width: "100%" },
    },
    "@keyframes blink-caret": {
        from: { borderColor: "transparent" },
        to: { borderColor: "black" },
        "50%": { borderColor: "black" },
    },
    scrollbar: {
        width: '100%', height: '20px', display: 'flex', alignItems: 'left',
        justifyContent: 'start', overflowY: 'scroll', msOverflowStyle: 'none',
        '&::-webkit-scrollbar': {
            width: '0px',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            background: 'transparent',
        },
        scrollbarWidth: '0px',
        scrollbarColor: 'transparent transparent',
        [theme.breakpoints.down('md')]: {
            height: '40px'
        }
    },
    mainContainer: {
        margin: '0px 2px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    questionType: {
        width: "194px",
        height: "28px",
        backgroundColor: '#52B8CC',
        padding: theme.spacing(1, 2),
        borderRadius: 20,
        display: 'flex',
        textTransform: 'capitalize',
        justifyContent: 'center',
        alignItems: 'center',
    },
    labelContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
        width: '100%',
        flexDirection: "column",
        justifyContent: "center"
    },
    recTag: {
        marginRight: theme.spacing(1),
    },
    svgIcons:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        gap:theme.spacing(2),
        [theme.breakpoints.down('sm')]:{
            gap:theme.spacing(1)
         }
      },
    'MuiSvgIcon-root':{
        marginBottom:theme.spacing(0.5),
        [theme.breakpoints.down('sm')]:{
           height:"0.7em",
           width:"0.8em"
        }
    },
}));

const States = {
    "IDLE": "IDLE",
    "FETCHING": "FETCHING",
    "SYNTHESIZE_SPEECH": "SYNTHESIZE_SPEECH",
    "RECORDING": "RECORDING",
    "ANALYSING": "ANALYSING",
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});


const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        border: "1px solid #FFFFFF",
        borderRadius: 8,
        position: 'relative',
        height: 520,
        maxWidth: 946,
        [theme.breakpoints.down('sm')]: {
            height: 490
        }
    }
}));

function Timer({ timeLeft, startTimer = false, onTimeOut = () => { }, setTimeLeft }) {
    const classes = useStyles();

    React.useEffect(() => {
        let timeoutId;

        if (!startTimer) return;

        if (timeLeft <= 0) {
            onTimeOut();
        } else {
            timeoutId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
        }

        return () => clearTimeout(timeoutId);
    }, [timeLeft, startTimer]);

    const minutes = Math.floor(timeLeft / 60).toString().padStart(2, '0');
    const seconds = (timeLeft % 60).toString().padStart(2, '0');

    return (
        <Box className={classes.timer}>
            <Typography variant="h5-medium" color="#000000" className={classes.timerheading}>
                Time remaining
            </Typography>
            <Typography variant="h5-medium" color="primary.clr-400">
                {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')} min
            </Typography>
        </Box>
    );
}

const Title=()=>{
    const classes = useStyles();
    return(
    <Box className={classes.svgIcons}>
        <CloseIcon className={classes['MuiSvgIcon-root']}/>
        <Typography variant="body01-medium">End Session</Typography> 
    </Box>)
}

export default function InteractiveDemoDialog({ open, onClose, details, isTeachAndTrain, isB2C }) {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const theme = useTheme()

    const [hasMicAccess, setHasMicAccess] = React.useState(false);
    const [timeLeft, setTimeLeft] = React.useState(0);
    const [state, setState] = React.useState(States.IDLE);
    const [startTimer, setStartTimer] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [chunck, setChunck] = React.useState("");
    const [isTyping, setIsTyping] = React.useState(false);

    const questionRef = React.useRef(null);
    const wavRecorderRef = React.useRef(null);
    const questionIndexRef = React.useRef(0);
    const previousAnswerRef = React.useRef('');
    const attemptRef = React.useRef('');
    const startTime = React.useRef(null);
    const speechRecorder = React.useRef(null);
    const transcript = React.useRef('');
    const audioRef = React.useRef(null);
    const scrollableRef = React.useRef(null);

    const desktop = React.useMemo(() => window.innerWidth > 700, [open]);
    const totalQuestion = React.useMemo(() => isTeachAndTrain ? 2 : 4, [open]);

    const isSmallScreen = window.innerWidth < theme.breakpoints.values.sm;

    React.useEffect(() => {
        if (!open) return;

        (async () => {
            if (!(await checkPermission('microphone'))) {
                const isPermitted = await askPermission({ video: false, audio: true });
                isPermitted ? setHasMicAccess(true) : setHasMicAccess(false);
            } else {
                setHasMicAccess(true)
            }

            fetchQuestion(true);
        })();
    }, [open]);

    const typeText = (textToType) => {
        setChunck('');
        setIsTyping(true);
        let charIndex = 0;

        const typeInterval = setInterval(() => {
            if (charIndex < textToType.length) {
                setChunck((prevText) => prevText + textToType.charAt(charIndex));
                charIndex++;
            } else {
                onTypingOver();
                clearInterval(typeInterval);
                setIsTyping(false);
            }
        }, 60);
    };

    const onTypingOver = React.useCallback(() => setState(States.IDLE), []);

    const fetchQuestion = async (defaultQuestion) => {
        try {
            setLoading(true);
            setState(States.FETCHING);
            const newQuestion = await generateDemoQuestion({
                shouldCreateDefaultQuestion: defaultQuestion,
                interviewTemplate: details.template,
                level: details.level,
                serialNumber: questionIndexRef.current,
                jobRole: details.jobRole,
                answer: previousAnswerRef.current,
                forTNT: isTeachAndTrain,
                prevQuestion: questionRef?.current?.question || "",
            });

            questionRef.current = newQuestion;
            toSpeech(newQuestion.question);
            setTimeLeft(newQuestion?.allottedTime);

        } catch (error) {
            console.error(error.message);
            setState(States.IDLE);
            snackbar.showSnackbar('Oo oh! unable to fetch question, try again', 'error');
        } finally {
            setLoading(false);
        }
    }

    const startAnswering = React.useCallback(async () => {
        await speechRecorder.current?.start();

        setStartTimer(true);
        startTime.current = Date.now();
        setState(States.RECORDING);
    }, []);

    const submit = () => {
        if (speechRecorder.current) {
            speechRecorder.current.stop(handleAudioSuccess);
        }

        setLoading(true);
        setStartTimer(false);
        setState(States.ANALYSING);
    };

    const handleAudioSuccess = async (result) => {

        const analysisData = {
            attemptId: attemptRef.current,
            questionId: questionRef.current?._id,
            timeTaken: Math.round((Date.now() - startTime.current) / 1000) || 0,
            serialNumber: questionIndexRef.current,
            template: details.template,
            audioBlob: result.audioBlob,
            forTNT: isTeachAndTrain,
        };

        analyseAnswer(analysisData, (event, data) => {

            if (event === 'error' || data?.analysis?.isError) {
                setLoading(false);
                setState(States.IDLE);
                setTimeLeft(questionRef.current?.allottedTime);
                console.error('error ', data);
                transcript.current = '';
                snackbar.showSnackbar('Oo oh! analysis failed, try again', 'error');
            }

            if (event === 'analysed' && !data?.analysis?.isError) {
                setLoading(false);

                questionIndexRef.current += 1;
                attemptRef.current = data?.attempt;
                previousAnswerRef.current = data?.transcript;
                transcript.current = '';

                if (questionIndexRef.current >= totalQuestion) finish();
                else fetchQuestion(false);
            }
        });
    }

    const finish = React.useCallback(async () => {
        if (questionIndexRef.current > 0) {
            try {
                setLoading(true);
                let feedbackUrl = await finishInterviewAttempt(
                    attemptRef.current
                );
                 feedbackUrl = isB2C
                ? `${feedbackUrl}&b2c=true`
                : feedbackUrl;

                window.open(feedbackUrl, '_blank');

                localStorage.setItem('recent-demo-feedback-url', feedbackUrl);
            } catch (error) {
                console.error(error.message);
                snackbar.showSnackbar('Oo oh! unable to finish the test.', 'error');
            } finally {
                setLoading(false);
                reset();
                onClose(true);
            }
        } else {
            reset();
            onClose();
        }
    }, []);

    const reset = React.useCallback(() => {
        if (audioRef.current && !audioRef.current.paused) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
        if (speechRecorder?.current) {
            speechRecorder?.current?.destructor();
            speechRecorder.current = null;
        }
        questionIndexRef.current = 0;
        previousAnswerRef.current = '';
        attemptRef.current = '';
        questionRef.current = null;
        transcript.current = '';
        setChunck('');
    }, []);

    const handleTimeout = React.useCallback(() => submit(), []);

    const onTranscriptAvailable = React.useCallback((result) => transcript.current = result, []);

    React.useEffect(() => {
        if (speechRecorder.current === null) {
            const isMobile = false;
            const serverUrl = process.env.REACT_APP_WEB_SOCKET_URL;
            const query = { token: Session.accessToken, application: 'none' };

            speechRecorder.current = new SpeechRecorder({
                isMobile, serverUrl, query, onInterimTranscript: onTranscriptAvailable
            });

        }
    }, [open]);

    const toSpeech = React.useCallback(async (text = '') => {
        setState(States.SYNTHESIZE_SPEECH);

        typeText(text);

        const input = {
            engine: 'neural', languageCode: 'en-GB', voiceId: 'Amy', text
        };

        const { audioUrl } = await synthesizeSpeech(input);
        if (audioRef.current) {
            try {
                audioRef.current.src = audioUrl;
                audioRef.current.play();
            } catch (error) {
                console.error(error);
            }
        }
    }, []);

    React.useEffect(() => {
        if (scrollableRef.current)
            scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
    }, [transcript.current]);

    return (
        <CustomDialog
            open={open}
            TransitionComponent={Transition}
            fullWidth
            keepMounted
            className={classes.dialog}
        >

            {(loading) ?
                <Box className={classes.waveBox}>
                    {questionIndexRef.current + 1 >= totalQuestion ? (
                        <AnalysingEffect />
                    ) : (
                        <Box>
                            <Typography variant="h2-medium" color="#1961BF">
                                Hang on! Coming up with the follow-up
                                question based on your response
                            </Typography>
                            <WaveEffect isActive={true} />
                        </Box>
                    )}

                </Box>

                :
                <>
                    <Box className={classes.dialogContent}>
                        <Box className={classes.BlueBox}>
                            <audio ref={audioRef}></audio>

                            <Box className={classes.LogoBox}>
                                <img 
                                    src="https://assets.languify.in/images/Languify_Logo.png"
                                    alt="Languify Logo" className={classes.logoImage} />
                                <Typography variant="h5-semiBold" color="shades.clr-black-900"
                                    className={classes.logoText}>
                                    Product Demo
                                </Typography>
                            </Box>
                            <CustomButton
                                disabled={
                                    [
                                        States.ANALYSING,
                                        States.FETCHING,
                                        States.RECORDING
                                    ].includes(state)
                                }
                                onClick={finish}
                                variant="outlined"
                                size={isSmallScreen ? 'small' : 'medium'}
                            >
                                <Title/>
                            </CustomButton>


                        </Box>
                        <div style={{ position: "relative" }}>
                            <Timer
                                timeLeft={timeLeft}
                                startTimer={startTimer}
                                onTimeOut={handleTimeout}
                                setTimeLeft={setTimeLeft}
                            />
                            <br />
                            <SpeechAnimation
                                play={state === States.SYNTHESIZE_SPEECH}
                            />
                            <br />

                            <Box className={classes.mainContainer}>
                                <Box
                                    className={classes.labelContainer}
                                >
                                    <Typography
                                        variant="h5-semiBold"
                                        color="#1961BF"
                                        className={classes.question}
                                    >
                                        Question:
                                        {
                                            String(questionIndexRef.current + 1).padStart(2, '0')}/{String(totalQuestion).padStart(2, '0')}
                                    </Typography>
                                    {isTeachAndTrain && (
                                        <Box className={classes.questionType}>
                                            <Typography variant="body01-bold"
                                                style={{ color: '#F0FDFF' }}>
                                                {questionRef?.current?.type} question
                                            </Typography>
                                        </Box>
                                    )}

                                </Box>

                                <Box className={classes.textContainer}>
                                    <Typography
                                        variant="h5-medium"
                                        color="neutral.clr-700"
                                        className={classes.text}
                                    >
                                        {chunck}
                                    </Typography>
                                </Box>


                                {[States.IDLE, States.SYNTHESIZE_SPEECH].includes(state) &&
                                    <Box>
                                        <CustomButton
                                            variant="contained" size="medium"
                                            disabled={!hasMicAccess || States.SYNTHESIZE_SPEECH === state}
                                            onClick={startAnswering}
                                            sx = {{textTransform:'none'}}
                                        >
                                            Record Answer
                                        </CustomButton>

                                    </Box>
                                }

                                {[States.ANALYSING].includes(state) &&
                                    <Button variant="contained" size="small"
                                        disabled={true}
                                        style={{
                                            textTransform: 'none', color: 'white',
                                            fontWeight: 600
                                        }}
                                    >
                                        Analysing...
                                    </Button>}

                                {[States.FETCHING].includes(state) &&
                                    <Button variant="contained" size="small"
                                        disabled={true}
                                        style={{
                                            textTransform: 'none', color: 'white',
                                            fontWeight: 600
                                        }}
                                    >
                                        Generating next question based on your response...
                                    </Button>}

                                {[States.RECORDING].includes(state) &&
                                    <Box display="flex" flexDirection="row" alignItems="center">
                                        <Typography variant="body01-bold" color="primary.clr-black-900"
                                            className={classes.recTag}>
                                            Rec
                                        </Typography>
                                        <Box display='flex' alignItems='center'
                                            className={classes.recordingControls}>
                                            <ListeningTag active={true} />
                                            <CustomButton
                                                variant="contained"
                                                size="medium"
                                                disabled={!hasMicAccess}
                                                onClick={submit}
                                            >
                                                Submit Answer
                                            </CustomButton>
                                        </Box>
                                    </Box>
                                }

                                {(!hasMicAccess) && <Typography
                                    fontSize={17} fontWeight={600}
                                    mt={2} color='error' fontFamily='Lato' mb={1}
                                >
                                    Please provide microphone access to proceed!
                                </Typography>}


                            </Box>
                        </div>
                    </Box>
                </>
            }
        </CustomDialog>
    );
}