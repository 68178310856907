import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";

import {animation} from "../../utils/index"
import { IconButton } from '../../components/CustomButton';

const features=[
    [ "Quizzes",
    "Daily learning reinforcement assignments",
    "Provide one conversational assignment to your students after each day of lecture. Let them talk with the AI 1:1 just like they do with the teacher and get feedback!"],
    ["AI curriculum",
    "Linked with education programs",
    "Set up AI based assessments and homework according to your curriculum, and use the scores to grade them automatically."],
    [ "Placement",
    "Company & role specific personalized AI preparation",
    "Automate placement preparation by having students practice personalized mock interviews based on their dream role and company."]
]

const useStyles = makeStyles((theme) => ({
    container:{
        background: 'url("https://assets.languify.in/images/Charcoal+Smudge.png")',
        width:"100%",
        position:"relative",
        minHeight:"700px",
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        gap:theme.spacing(10),
        textAlign:"center",
        padding:theme.spacing(8,37.5),
        [theme.breakpoints.down('lg')]:{
            padding:theme.spacing(8,15),
        },
        [theme.breakpoints.down('md')]:{
            padding:theme.spacing(8,10),
        },
        [theme.breakpoints.down('sm')]:{
            height:"auto",
            padding:theme.spacing(8,5),
        }
    },
    heading:{
        opacity:0,
        transform:"translateY(-20px)",
        transition:"all 500ms ease",
        ...theme.typography['h2-medium'],
        [theme.breakpoints.down('sm')]:{
          ...theme.typography['h3-medium'],
          fontSize: '33px !important'
      }
    },
    features:{
        display:"flex",
        flexDirection:"row",
        textAlign:"left",
        flexWrap:"wrap",
        width:"100%",
        gap:theme.spacing(4.5),
        justifyContent:"center",
        opacity:0,
        transform:"translateY(40px)",
        transition:"all 500ms ease",
        [theme.breakpoints.down('sm')]:{
            gap:theme.spacing(3),
        }
    },
    featuresItem:{
        display:"flex",
        flexDirection:"column",
        gap:theme.spacing(16),
        background: 
        'linear-gradient(278.27deg, #4B6CB7 0%, #002759 112.69%),linear-gradient(0deg,#FFFFFF, #FFFFFF)',
        height:"480px",
        width:"350px",
        minHeight:"fit-content",
        padding:theme.spacing(15,9,9,9),
        borderRadius:theme.spacing(1),
        [theme.breakpoints.between('800','lg')]:{
            gap:theme.spacing(12),
            justifyContent:"space-between"
        },
        [theme.breakpoints.down('800')]:{
            padding:theme.spacing(7,7,10,7),
            justifyContent:"space-around",
            height:"fit-content",
            gap:theme.spacing(5),
        }
    },
    featuresHeading:{
        display:"flex",
        flexDirection:"column",
        gap:theme.spacing(5),
        [theme.breakpoints.between('800','lg')]:{
            justifyContent:"space-around",
            gap:theme.spacing(5),
        },
        [theme.breakpoints.down('400')]:{
            justifyContent:"space-around",
        }
    },
    featurestitle:{
        color: "transparent",
        textShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
        backgroundClip: "text",
        backgroundColor:theme.palette.shades['clr-white-900'],
        [theme.breakpoints.down('md')]: {
            fontSize: '44px !important',
        },
    },
    descriptionBox:{
        background:"rgba(255, 255, 255, 0.15)",
        padding:theme.spacing(5),
        height:"200px",
        minHeight:"fit-content",
        borderRadius:8,
        [theme.breakpoints.down('400')]:{
            height:"220px"
        }
    },
    enterFromY:{
        opacity:1,
        transform:"translateY(0px)",
    },
    button:{
        display:"flex",
        justifyContent:"center"
    },
    textDecoration:{
        textDecoration:"none"
    }
}));


export default function Automate() {
    const classes = useStyles();
    const theme=useTheme();

    React.useEffect(()=>{
        const handleScroll = () => {
            animation(`.${classes.heading}`, `${classes.enterFromY}`,200);
            animation(`.${classes.features}`, `${classes.enterFromY}`,200);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      },[])

    return (
        <Box className={classes.container}>
            <Typography className={classes.heading}>
                What Languify can automate for you?
            </Typography>
            <Box className={classes.features}>
                {features.map((value, index) => (
                    <Box key={index} className={classes.featuresItem}>
                        <Box className={classes.featuresHeading}>
                        <Typography className={classes.featurestitle} variant="h1-medium"
                         color="shades.clr-white-900" >
                            {value[0]}
                        </Typography>
                        <Typography variant="h5-medium" color="shades.clr-white-900">
                            {value[1]}
                        </Typography>
                        </Box>
                        <Box className={classes.descriptionBox}>
                            <Typography className={classes.description} variant="h6-medium"
                             color="shades.clr-white-900"
                             >
                                {value[2]}
                            </Typography>
                        </Box>
                    </Box>
                ))}
                </Box>
                <Box className={classes.button}>
                    <a 
                      target='_blank' 
                      rel='noreferrer' 
                      href={process.env.REACT_APP_GET_IN_TOUCH_TALLY_FORM_URL}
                      className={classes.textDecoration}
                    >
                        <IconButton label='Book a call' 
                        bgColor={theme.palette.primary.main}
                        color={theme.palette.shades['clr-white-900']}
                        icon='https://assets.languify.in/images/message-chat_white.svg' />
                    </a>
                </Box>
        </Box>
    );
}