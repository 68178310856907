import React from 'react'
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2.5),
    width: '100%',
    marginBottom: theme.spacing(7),
    backgroundColor: theme.palette.success['clr-100'],
    padding: theme.spacing(3, 5, 5.5, 5),
    textAlign: 'center',
    [theme.breakpoints.up('500')]:{
      display:"none"
    }
  },
  imageContainer: {
    position: 'relative',
    display: 'inline-block',
  },
  bgImage: {
    display: 'block',
  },
  smileImage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const PracticeLanguify = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Typography variant='h6-medium' color='success.clr-700'>
        Practice with Languify AI & Get personalized feedback for your each practice attempt.
      </Typography>
      <div className={classes.imageContainer}>
        <img   
            src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/bgSmile.png' 
            alt='bg' 
            className={classes.bgImage} />
        <img 
            src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/smile.png' 
            alt='smile' 
            className={classes.smileImage} />
      </div>
    </Box>
  );
}

export default PracticeLanguify;
