import { PollyClient, SynthesizeSpeechCommand } from "@aws-sdk/client-polly";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";

const client = new PollyClient({
    region: process.env.REACT_APP_AWS_REGION,
    credentials: fromCognitoIdentityPool({
        clientConfig: { region: process.env.REACT_APP_AWS_REGION },
        identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    })
});

/**
 * Synthesize speech using AWS Polly.
 *
 * @async
 * @param {Object} input - The input parameters for speech synthesis.
 * @param {string} [input.engine='standard'] - The speech synthesis engine.
 * @param {string} [input.languageCode='en-IN'] - The language code for the speech.
 * @param {string[]} [input.lexiconNames=[]] - Array of lexicon names.
 * @param {string} [input.outputFormat='mp3'] - The output format for the audio.
 * @param {string|number} [input.sampleRate='8000'] - The sample rate for the audio.
 * @param {string} [input.text='Turning Scripts into Speech: Where Text Comes to Life'] - The text to synthesize.
 * @param {string} [input.voiceId='Aditi'] - The voice ID for the speech.
 * @returns {Promise<{
*   response: object,
*   audioBlob: Blob,
*   audioUrl: string
* }>} An object containing the response, audioBlob, and audioUrl.
* @throws {Error} If there's an error during speech synthesis.
*/

export default async function synthesizeSpeech(input) {

    try {
        console.log('synthesizeSpeech begin ->');

        const params = {
            Engine: input?.engine || 'standard',
            LanguageCode: input?.languageCode || 'en-IN',
            LexiconNames: input?.lexiconNames || [],
            OutputFormat: input?.outputFormat || 'mp3',
            SampleRate: input?.sampleRate || '22050',
            Text: input?.text || 'Turning Scripts into Speech: Where Text Comes to Life',
            VoiceId: input?.voiceId || 'Raveena',
        };

        const command = new SynthesizeSpeechCommand(params);

        const response = await client.send(command);

        const audioStream = response.AudioStream;

        // Read the audio stream in chunks and collect the data
        const chunks = [];

        const reader = audioStream.getReader();
        while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            chunks.push(value);
        }
        reader.releaseLock();

        // Combine the chunks into a single Uint8Array
        const audioData = Uint8Array.from(chunks.reduce((acc, chunk) => [...acc, ...chunk], []));

        const audioBlob = new Blob([audioData], { type: response.ContentType });
        const audioUrl = URL.createObjectURL(audioBlob);

        return { response, audioBlob, audioUrl };

    } catch (error) {
        console.error('synthesizeSpeech::Error ->', error);

        throw error;
    }
}
