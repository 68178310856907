import React from "react";
import { useLocation } from "react-router-dom";

import AppShell from "components/AppShell";
import Main from "./Main";
import Demo from "components/Demo";
import ProductBreakdown from "./ProductBreakdown";
import SoftwareSection from "components/SoftwareSection";
import Faq from "components/Faq";
import { ProductVision } from "./ProductVision";
import TestimonialSection from "components/TestimonialSection";
import ClientsSection from "components/ClientsSection";

function TeachAndTrain() {
  const elementToScrollRef = React.useRef(null);
  const location = useLocation();

  const showDemo = React.useCallback(() => {
    if (elementToScrollRef.current) {
      elementToScrollRef.current.scrollIntoView({behaviour:"smooth",block:"center"});
    }
  }, []);

  React.useEffect(() => {
    if (location.hash === '#demo') showDemo();
  }, [location,showDemo]);

  return (
    <AppShell>
      <Main onExperienceLive={showDemo} />
      <ClientsSection/>
      <Demo elementToScrollRef={elementToScrollRef} />
      <ProductVision/>
      <ProductBreakdown onExperienceLive={showDemo} />
      <TestimonialSection/>
      <SoftwareSection/>
      <Faq />
    </AppShell>
  );
}

export default TeachAndTrain;
