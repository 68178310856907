import Box from "@mui/material/Box";

export default function LinearGradient({ children }) {

    return (
        <Box style={{
            marginTop: "30px",
            height: '100%',
            width: '100%',
            background: 'linear-gradient(181deg, rgba(45, 171, 255, 0.00) 0%, rgba(149, 213, 255, 0.40) 49.48%, #FFF 100%)'
        }}>
            {children}
        </Box>
    );
}