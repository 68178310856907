import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

import InstituteCard from './InstituteCard';
import { setupIntersectionObserver } from 'utils/observerUtil';


const useStyles = makeStyles((theme) => ({
    impactContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        opacity: 0,
        transform: 'translateY(50px)',
        transition: 'transform 0.4s ease-in-out, opacity 1s ease-in-out',
    },
    gradientTop: {
        width: '100%',
        height: '16px',
        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.36) 100%)',
    },
    gradientBottom: {
        width: '100%',
        height: '16px',
        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.36) 100%)',
        transform: 'rotate(180deg)',
    },
    impactBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        padding: theme.spacing(11,37.5),
        gap: theme.spacing(10),
        [theme.breakpoints.down("lg")]: {
            padding : theme.spacing(11,15),
          },
          [theme.breakpoints.down("md")]: {
            padding : theme.spacing(11,10),
            justifyContent: 'flex-start',
          },
        [theme.breakpoints.down("sm")]: {
          padding : theme.spacing(3,5),
          justifyContent: 'flex-start',
        },
    },
    headBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(4),
        textAlign: 'center',
    },
    typo: {
        background: `linear-gradient(278.27deg, #4B6CB7 0%, #002759 112.69%) , 
                     linear-gradient(0deg, #FFFFFF, #FFFFFF)`,
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        textFillColor: 'transparent',
        ...theme.typography['h2-medium'],
        [theme.breakpoints.down("md")]: {
            ...theme.typography['h4-medium'],
           }
    },
    typo2:{
        ...theme.typography['h4-medium'],
        [theme.breakpoints.down("md")]: {
            ...theme.typography['h6-medium'],
           }
    }
}));

const TestimonialSection = () => {
    const classes = useStyles();
    const [visible, setVisible] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        const cleanup = setupIntersectionObserver(containerRef, setVisible, 0.5)

        return cleanup;
    }, []);

    return (
        <Box
            ref={containerRef}
            className={classes.impactContainer}
            style={{
                transform: visible ? 'translateY(0)' : 'translateY(50px)',
                opacity: visible ? 1 : 0,
            }}
        >
            <Box className={classes.gradientTop}></Box>
            <Box className={classes.impactBox}>
                <Box className={classes.headBox}>
                    <Typography className={classes.typo} >
                        Languify's Impact - Real Stories, Real Growth
                    </Typography>
                    <Typography color='success.clr-700' className={classes.typo2}>
                        An overview of how Languify AI is driving customer growth and success 
                        for industry leaders
                    </Typography>
                </Box>
                <InstituteCard />
            </Box>
            <Box className={classes.gradientBottom}></Box>
        </Box>
    );
};

export default TestimonialSection;
