import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { trackCustomEvent } from "utils";
import { Link } from "react-router-dom";
import { Menu as MenuIcon } from '@mui/icons-material';
import { Typography, Box, Button as MUIButton, Slide } from "@mui/material";

import Sidebar from 'components/Sidebar';
import HeaderMenu from "components/HeaderMenu";
import logo from "./../assets/languify_logo.svg";

const useStyles = makeStyles(theme => ({
  header: {
    height: "104px",
    width: "100%",
    left: "0px",
    top: "0px",
    padding: theme.spacing(7, 37.5),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    justifyContent: "space-between",
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(0, 15),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 10),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 5),
    },
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: 1400,
    margin: "0 auto",
    padding: theme.spacing(0, 13.5),
    [theme.breakpoints.down("md")]: {
      width: "85%"
    },
    [theme.breakpoints.down("md")]: {
      width: "95%",
      padding: 0
    }
  },
  desktopElement: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "right",
    width: "100%",
    [theme.breakpoints.down("1000")]: {
      display: "none"
    }
  },
  elements: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(11),
    [theme.breakpoints.down("xl")]: {
      gap: theme.spacing(4),
    },
    [theme.breakpoints.down("lg")]: {
      gap: theme.spacing(2.5),
    }
  },
  menuItems: {},
  mobileElement: {
    display: "none",
    cursor: "pointer",
    height: "24px",
    width: "24px",
    [theme.breakpoints.down("1000")]: {
      display: "block",
    }
  },
  downloadButtonStyle: {
    borderRadius: "8px",
    padding: theme.spacing(3.75, 7.5),
  },
  signinButtonStyle: {
    color: theme.palette.shades['clr-white-900'],
    textTransform: "none",
    marginRight: theme.spacing(10),
    height: "10%"
  },
  logoContainer: {
    cursor: "pointer",
    width: "140px",
    [theme.breakpoints.down("md")]: {
      height: "28px",
    },
  },
  logo: {
    width: "140px",
    [theme.breakpoints.down("sm")]: {
      width: "110px"
    },
  },
  studentElement: {
    minWidth: "156px",
    height: "48px",
    borderRadius: "4px",
    border: `2px solid ${theme.palette.primary.main}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    [theme.breakpoints.down("1000")]: {
      display: "none"
    },
  },
  menuLinkText: {
    minWidth: "106px",
    padding: theme.spacing(0),
    textTransform: "none",
    textAlign: "center",
    '&:hover': {
      backgroundColor: 'transparent',
      '& $hoverableText': {
        color: theme.palette.neutral.clr - 600,
      },
    },
  },
  hoverableText: {
    transition: 'color 0.3s ease',
    ...theme.typography['h6-semiBold'],
    color: theme.palette.shades['clr-black-900'],
    textTransform: "none",
    whiteSpace: "nowrap"
  },
  selected: {
    color: `#1961BF !important`,
  },
  sidebarWrapper: {
    position: 'fixed',
    top: 0,
    right: 0,
    height: '100%',
    width: '100%',
    zIndex: 1300,
  },
  sidebarContainer: {
    width: '100%',
    height: '100%',
    zIndex: 1,
    overflowX: 'hidden',
  },
  anchorTag: {
    textDecoration: 'none',
  }
}));

export const MenuItems = [
  { title: "About Us", link: "/aboutus" },
  { title: "Pricing", link: "/pricing" },
  { title: "Get In Touch", link: process.env.REACT_APP_GET_IN_TOUCH_TALLY_FORM_URL, newPage: true },
];

const productData = [
  {
    id: 'teach&Train', label: 'Teach & Train', link: '/teach&train'
  },
  {
    id: 'inprep', label: 'In-prep pathway', link: '/inprep'
  },
];

const useCaseData = [
  {
    id: 'education', label: 'Education', link: '/usecase/education',
  },
  {
    id: 'interview', label: 'Interview Preparation', link: '/usecase/interview-preparation',
  }
]

const MenuLink = ({ title, link, newTab, isSelected, onClick }) => {
  const classes = useStyles();
  const handleLinkClick = () => {
    if (title === 'Get In Touch') trackCustomEvent('get_in_touch_inprep');
    onClick();
  }

  return newTab ? (
    <a
      rel='noreferrer'
      href={link}
      target={"_blank"}
      className={`${classes.menuLinkText} ${isSelected ? classes.selected : ''}`}
    >
      <MUIButton>
        <Typography className={`${classes.hoverableText} ${isSelected ? classes.selected : ''}`}>
          {title}
        </Typography>
      </MUIButton>
    </a>
  ) : (
    <Link
      to={link}
      onClick={handleLinkClick}
      className={`${classes.menuLinkText} ${isSelected ? classes.selected : ''}`}
    >
      <MUIButton>
        <Typography className={`${classes.hoverableText} ${isSelected ? classes.selected : ''}`}>
          {title}
        </Typography>
      </MUIButton>
    </Link>
  );
}

function Header({ menuItems, isB2c }) {
  const classes = useStyles();
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showHeader, setShowHeader] = useState(false);

  useEffect(() => {
    setShowHeader(true);
    console.log(isB2c)
  }, []);

  const handleOpenSidebar = () => setSidebarOpen(true);

  const _menuItems = menuItems || MenuItems;

  return (
    <Slide direction="down" in={showHeader} mountOnEnter unmountOnExit timeout={500}>
      <Box className={classes.header}>
        <Box className={classes.logoContainer}>
          <Link to="/" style={{ textDecoration: "none" }}>
            <img src={logo} alt="languify" className={classes.logo} />
          </Link>
        </Box>
        {!isB2c && (
          <>
            <Box className={classes.desktopElement}>
              <Box className={classes.elements}>
                <HeaderMenu items={productData} title="Products" />
                <HeaderMenu items={useCaseData} title="Use Case" />
                {_menuItems.map((menu, index) => (
                  <MenuLink
                    key={index}
                    title={menu.title}
                    link={menu.link}
                    newTab={menu.newPage}
                    isSelected={selectedMenu === index}
                    onClick={() => setSelectedMenu(index)}
                  />
                ))}
              </Box>
            </Box>
            {!sidebarOpen && (
              <MenuIcon className={classes.mobileElement} onClick={handleOpenSidebar} />
            )}
            <Slide direction="left" in={sidebarOpen} mountOnEnter unmountOnExit timeout={500}>
              <Box className={classes.sidebarWrapper}>
                <Sidebar setSidebarOpen={setSidebarOpen} />
              </Box>
            </Slide>
          </>
        )}
      </Box>
    </Slide>
  );
}

export default Header;
