import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { setupIntersectionObserver } from 'utils/observerUtil'; 

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(15,37.5),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(12),
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out',
        opacity: 0,
        transform: 'translateY(50px)',
        [theme.breakpoints.down('xl')]: {
            padding: theme.spacing(15, 37.5),
        },
        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(15),
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(7, 10),
            gap: theme.spacing(7),
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(7, 5),
        },
    },
    containerVisible: {
        opacity: 1,
        transform: 'translateY(0)',
    },
    typo: {
        textAlign: 'center',
        ...theme.typography['h2-medium'],
    },
    accordion: {
        border: '1px solid',
        borderColor: theme.palette.neutral['clr-400'],
        borderRadius: '4px',
        boxShadow: 'none',
        '&:before': {
            display: 'none',
        },
        overflow: 'hidden',
        marginBottom: theme.spacing(5),
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(3),
        },
    },
    accordionExpanded: {
        marginBottom: '20px', 
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(3),
        },
    },
    accordionSummary: {
        backgroundColor: theme.palette.background.default,
        height: '68px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '4px 4px 0 0',
        padding: theme.spacing(0, 4),
        [theme.breakpoints.down('md')]: {
           height:'auto'
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
            width: '36px',
            height: '36px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'transform 0.5s ease-in-out',
        },
        '& .MuiSvgIcon-root': {
            fontSize: '36px',
            transition: 'transform 0.5s ease-in-out',
        },
    },
    accordionSummaryExpanded: {
        '& .MuiAccordionSummary-expandIconWrapper': {
            transform: 'rotateX(180deg)',
        },
    },
    accordionDetails: {
        backgroundColor: theme.palette.background.paper,
        overflow: 'hidden',
        borderTop: 'none', 
        paddingTop: 0,
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(10),
        transition: 'opacity 0.3s ease-in-out',
        opacity: 1,
    },
    typography: {
        ...theme.typography['h5-regular'],
        color: "#1961BF",
    },
    typographyVisible: {
        opacity: 1,
        transform: 'translateY(0)',
    },
}));

const faqs = [
    {
        question: "What is the difference between Teach & Train and Pathways?",
        answer: `Teach & Train is designed for improving learning outcomes while InPrep Pathways is 
        focused on improving placement outcomes.
        Accordingly, several functionalities within the solution are tailored differently. Depending
         on your use case, you can choose either platform or even both.
        `,
    },
    {
        question: "Will I be able to see the students’ performance?",
        answer: `Yes, you will have a separate dashboard where you can see all the data related to 
        the students - such as how many times they’ve attempted an assessment, their best performance, 
        weak areas, exact transcripts of what they spoke, and more. 
        `,
    },
    {
        question: "Can I get this white-labeled?",
        answer: `Yes! We provide white-labeling facilities so you can have your logo and branding on 
        the platform. The student will not know they are using Languify. 
        `,
    },
    {
        question: "How can I learn more about the product?",
        answer: `You can book a free demo for our platform using this link where we’ll discuss your 
        business situation in detail and work together to understand whether Languify can help you.

        We’ll also provide you a demo link that you can use to try out the software yourself from a 
        student’s perspective
        `,
    },
    {
        question: "I have some specific requirements. Can you customize this for me?",
        answer: `Depending on how much new features would need to be added and how niche those 
        features are, we are open to providing customisations on a case-by-case basis. We will need 
        to have a deeper discussion to understand the requirements first. Kindly book a call here.`,
    },
    {
        question: "How quickly can I get started?",
        answer: `We can have the system live, white-labeled and ready for your students’ use in less 
        than 7 working days. 
        `,
    },
    {   
        question: "Are you using ChatGPT?",
        answer: `We have our own custom LLM that we use to process the data from students and provide 
        evaluation. We built this in 2020 - two years before ChatGPT was released.`,
    },
];

export default function Faq() {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const cleanup = setupIntersectionObserver(sectionRef, setIsVisible, 0.5);

        return cleanup;
    }, []);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Box
            className={`${classes.container} ${isVisible ? classes.containerVisible : ''}`}
            ref={sectionRef}
        >
            <Typography className={classes.typo}>Frequently Asked Questions</Typography>
            <Box>
                {faqs.map((faq, index) => (
                    <Accordion
                        className={`${classes.accordion} ${expanded === index ? 
                            classes.accordionExpanded : ''}`}
                        key={index}
                        expanded={expanded === index}
                        onChange={handleChange(index)}
                        TransitionProps={{ timeout: 500 }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                            className={`${classes.accordionSummary} ${expanded === index ? 
                                classes.accordionSummaryExpanded : ''}`}
                        >
                            <Typography
                                variant='h5-medium'
                                className={`${classes.summaryTypography} ${expanded === index ? 
                                    'expanded' : 'collapsed'}`}
                            >
                                {index+1}.&nbsp;
                            </Typography>
                            <Typography
                                variant='h5-medium'
                                className={`${classes.summaryTypography} ${expanded === index ? 
                                    'expanded' : 'collapsed'}`}
                            >
                                {faq.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Typography className={`${classes.typography} ${expanded === index ? 
                                classes.typographyVisible : ''}`}>
                                {faq.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </Box>
    );
}
