import { Backdrop, LinearProgress, Box } from "@mui/material";
import React from "react";

const LoadingProviderContext = React.createContext({
  _loading: false,
  _setLoading: () => { },
});

const LoadingProvider = ({ children }) => {
  const [_loading, _setLoading] = React.useState(false);

  return (
    <LoadingProviderContext.Provider
      value={
        {
          _loading,
          _setLoading,
        }
      }
    >
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1000,
          width: "100vw",
          height: "100vh"
        }}
        open={_loading}
      >
        <Box width="100%" height="100%">
          <LinearProgress variant="indeterminate" />
        </Box>
      </Backdrop>
      {children}
    </LoadingProviderContext.Provider>
  );
};

const useLoading = () => {
  const { _setLoading } = React.useContext(LoadingProviderContext);

  const show = () => {
    _setLoading(true);
  };

  const hide = () => {
    _setLoading(false);
  };

  return { show, hide };
};

export { LoadingProviderContext, LoadingProvider, useLoading };