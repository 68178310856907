const { default: Client } = require("./_client");

export const sendCrashReport = async (error, errorInfo) => {
  const result = await new Client({
    path: '/feedback/report-crash',
    payload: {
      error, errorInfo
    }
  }).post();

  if (!(result.ok || result.code === 200))
    throw new Error(result.message || "Unable to send report!");
};