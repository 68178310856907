import React from "react";
import Box from "@mui/material/Box";
import AppShell from "components/AppShell";
import Button from "components/Button";
import InfoMessage from "components/InfoMessage";

const Message = "Something went wrong, please contact your administrator.";

function Error() {
  const handleBack = () => {
    // eslint-disable-next-line no-restricted-globals
    history.back();
  };

  return (
    <AppShell>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        p={3}
      >
        <img
          alt="error"
          src="https://assets.languify.in/images/error.svg"
        />
        <a href={`mailto::info@languify.in`} style={{ textDecoration: "none" }} >
          <Button variant="contained" >Contact Administrator</Button>
        </a>
        <Button variant="text" onClick={handleBack} >
          Go Back
        </Button>
        <InfoMessage variant="error">
          {Message}
        </InfoMessage>
      </Box>
    </AppShell>
  );
};

export default Error;