export const checkPermission = async (name) => {
    return await new Promise((resolve, reject) => {
        navigator.permissions.query(
            { name }
        ).then(function (permissionStatus) {
            if (permissionStatus.state === "granted") resolve(true);
            else resolve(false);
        }).catch(err => {
            console.error(err);
            resolve(false);
        });
    });
};

export const askPermission = async (permission) => {
    return await new Promise((resolve, reject) => {
        navigator.mediaDevices.getUserMedia(permission).then(stream => {
            resolve(true);
        }).catch(err => {
            console.error(err);
            resolve(false);
        });
    });
}