import React from 'react'
import { Route, Routes } from 'react-router-dom'

import Education from './Education'
import InterviewPrep from './InterviewPrep'

const UseCase = () => {
  return (
  <Routes>
    <Route path='/education' element={<Education />}/>
    <Route path='/interview-preparation' element={<InterviewPrep />} />
  </Routes>
  )
}

export default UseCase
