import React, { useState, useRef } from 'react';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { makeStyles } from '@mui/styles';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const useStyles = makeStyles((theme) => ({
  videoPlayer: {
    maxWidth: '750px',
    width: "60%",
    position: 'relative',
    borderRadius: "15px",
    overflow: "hidden",
    [theme.breakpoints.down('md')]: {
      width: '70%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%'
    },
  },
  video: {
    width: '100%',
    height: '100%',
    background: 'transparent',
    cursor: 'pointer',
    overflow: 'hidden'
  },
  controls: {
    display: 'flex',
    position: 'absolute',
    width: '100%',
    bottom: "2px",
    background: `linear-gradient(to bottom, transparent, ${theme.palette.common.black})`,
    alignItems: 'center',
    transition: 'opacity .2s',
  },
  controlButton: {
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
    opacity: 0.8,
    transition: 'all .2s',
    color: "white"
  },
  playButton: {
    height: '40px',
    width: '40px',
  },
  soundButton: {
    height: '40px',
    width: '40px',
  },
  fullscreenButton: {
    height: '35px',
    width: '35px',
  },
  timeline: {
    '-webkit-appearance': 'none',
    width: 'calc(100% - 125px)',
    height: '.5em',
    backgroundColor: 'rgba(255, 255, 255, .3)',
    borderRadius: '5px',
    backgroundSize: '0% 100%',
    backgroundImage: 'linear-gradient(#fff, #fff)',
    backgroundRepeat: 'no-repeat',
  },
  thumbnailOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
    cursor: 'pointer',
  },
  playOverlayButton: {
    position: 'absolute',
    color: "black",
    backgroundColor: "white",
    padding: "10px",
    fontSize: "60px",
    borderRadius:"50%",
    zIndex: 3,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
    transition: 'all 0.3s ease',
    '&:hover': {
      fontSize: "80px"
    },
  },
  thumbnailImage: {
    maxWidth: '100%',
    maxHeight: '100%',
  }
}));


function VideoPlayer({link,thumbnail}) {
  const classes = useStyles();
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const videoRef = useRef(null);


  const handlePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
  };

  const handleSeek = (event) => {
    const newTime = (event.target.value / 100) * videoRef.current.duration||0;
    videoRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const handleSoundToggle = () => {
    videoRef.current.muted = !videoRef.current.muted;
  };

  const handleFullscreenToggle = () => {
    if (!isFullScreen) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      } else if (videoRef.current.webkitRequestFullscreen) {
        videoRef.current.webkitRequestFullscreen();
      } else if (videoRef.current.msRequestFullscreen) {
        videoRef.current.msRequestFullscreen();
      }
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      setIsFullScreen(false);
    }
  };

  return (
    <div className={`${classes.videoPlayer} ${isPlaying ? classes.playing : ''}`}>
      {!isPlaying && (
        <div className={classes.thumbnailOverlay} onClick={handlePlayPause}>
          <img src={thumbnail} loading='lazy'
            alt="Video Thumbnail" className={classes.thumbnailImage} 
          />
          <PlayArrowIcon className={classes.playOverlayButton} />
        </div>
      )}
      <video
        id="video"
        className={classes.video}
        ref={videoRef}
        onTimeUpdate={handleTimeUpdate}
        onEnded={() => {
          setIsPlaying(false);
        }}
        src={link}
        type="video/mp4"
        onClick={handlePlayPause}
      />
      <div className={classes.controls}>
        <button className={`${classes.playButton} ${classes.controlButton}`} onClick={handlePlayPause}>
          {isPlaying ? <PauseCircleOutlineIcon/> : <PlayCircleOutlineIcon/>}
        </button>
        <input
          type="range"
          min="0"
          max="100"
          className={classes.timeline}
          value={(currentTime / videoRef.current?.duration) * 100||0
          }
          onChange={handleSeek}
        />
        <button className={`${classes.soundButton} ${classes.controlButton}`} onClick={handleSoundToggle}>
          {videoRef.current && videoRef.current.muted ? <VolumeOffIcon/> : <VolumeUpIcon/>}
        </button>
        <button className={`${classes.fullscreenButton} ${classes.controlButton}`}  onClick={handleFullscreenToggle}>
          <FullscreenIcon/>
        </button>
      </div>
    </div>
  );
}

export default VideoPlayer;
