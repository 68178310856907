import React from 'react'
import { Box , Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';

import { ProductDemoVideo } from 'components/ProductDemoVideo';
import AppShell from 'components/AppShell'
import HeroSection from 'components/HeroSection'
import PracticeLanguify from 'components/PracticeLanguify';
import Demo from 'components/Demo';

const useStyles = makeStyles((theme) => ({
  typography:{
    fontFamily:theme.typography['h1-medium'].fontFamily,
    fontSize: "57px",
    fontWeight: 500,
    lineHeight: "68.4px",
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
       fontSize: "48px",
       lineHeight: "59.52px",
      }
}
}));

const B2C = () => {
    const classes = useStyles();

    const elementToScrollRef = React.useRef(null);
    const location = useLocation();
    
    const showDemo = React.useCallback(() => {
      if (elementToScrollRef.current) {
        elementToScrollRef.current.scrollIntoView({ behavior: "smooth",block:"center" });
      }
    }, []);
  
    React.useEffect(() => {
      if (location.hash === "#demo") showDemo();
    }, []);

    const title = (
        <Box>
            <Typography className={classes.typography} >
              Will you be able to clear your next Interview?
            </Typography>
        </Box>
    );
  return (
    <AppShell B2C={true}>
        <HeroSection 
            title={title} 
            img={["https://languify-assets.s3.ap-south-1.amazonaws.com/images/hero_section_b2c.png" ]}
            subTitle='Take a free mock interview and find out'
            hoverBtnText='Try it Now (For Free)'
            B2C={true}
            onExperienceLive={showDemo}
        />
        <Demo elementToScrollRef={elementToScrollRef} b2c={true}/>
        <ProductDemoVideo link={"https://assets.languify.in/video/b2c_final.mp4"}
            thumbnail={"https://languify-assets.s3.ap-south-1.amazonaws.com/images/b2c_thumbnail.png"}
          />
        {
          <PracticeLanguify/>
        }
    </AppShell>
  )
}

export default B2C
