import React from "react";
import ImageInfo from "./ImageInfo";
import { styled } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import {animation} from "../utils/index"

const useStyles = makeStyles((theme) => ({
    ourMentorsContainer:{
        display:"flex",
        flexDirection:"row",
        flexWrap:"wrap",
        gap:theme.spacing(5),
        textAlign:"center",
        marginTop:0,
        width:"90%",
        [theme.breakpoints.up('xl')]:{
            width:"70%"
        }
    },
    ourTeamsContainer:{
        width:"100%",
        [theme.breakpoints.up('xl')]:{
            width:"70%"
        }
    },
    tranfromFromBottom:{
        opacity:0,
        transform:"translateY(150px)",
        transition:"all 500ms ease",
      },
      tranfromFromDiagonal:{
        opacity:0,
        transform:" translateX(50px) translateY(50px)",
        transition:"all 500ms ease",
        transitionDelay: "500ms"
      },
      enterFromDiagonal:{
        opacity:1,
        transform:" translateX(0) translateY(0)",
      },
      enterFromX:{
        opacity:1,
        transform:"translateX(0px)",
      },
      enterFromY:{
        opacity:1,
        transform:"translateY(0px)",
      },
}))
const FlexBox = styled("div")(({ theme, width }) => ({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    margin: "0.5em auto 5em auto",
    width: width,
    [theme.breakpoints.down("sm")]: {
        width: "98%"
    },
    [theme.breakpoints.down("md")]: {
        width: "90%"
    },
}));

function ImagesFlexbox({ flexboxTitle, imagesInfo, width, isMentor }) {
    const classes = useStyles();
    React.useEffect(()=>{
        const handleScroll = () => {
            animation(`.${classes.tranfromFromBottom}`, `${classes.enterFromY}`,20);
            animation(`.${classes.tranfromFromDiagonal}`, `${classes.enterFromDiagonal}`,20);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
      },[])
      
    return (
        <>
            <FlexBox 
              width={width} 
              className={`${isMentor ? `${classes.ourMentorsContainer} ${classes.tranfromFromDiagonal}` 
                : `${classes.tranfromFromBottom} ${classes.ourTeamsContainer}`}`} >
                {
                    imagesInfo.map((image, index) => (
                        <ImageInfo
                            key={index}
                            src={image.url}
                            name={image.name}
                            title={image.role}
                            linkedinUrl={image.linkedinUrl}
                            isMentor={isMentor}
                        />
                    ))
                }
            </FlexBox>
        </>
    );
}

export default ImagesFlexbox;
