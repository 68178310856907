import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles((theme) => ({
    containerBox:{
        display: 'flex',
        alignItems: 'flex-end',
        backgroundSize: 'cover',
        borderRadius:"4px",
        '& > div': {
            transition: "all 400ms ease-in",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column-reverse",
            borderRadius: 8,
        }
    },
    ourTeams:{
        height:"350px",
        width:"270px",
        padding:theme.spacing(1),
        display:"flex",
        flexDirection:"column",
        gap:theme.spacing(3),
        [theme.breakpoints.between('lg','xl')]: {
            height:"320px",
            width:"230px"
         },
         [theme.breakpoints.between('sm','lg')]: {
            height:"280px",
            width:"220px"
         },
    },
    border: {
        background:theme.palette.shades['clr-white-900'],
        borderRadius:"4px",
      },
      borderOverlay: {
        height:"354px",
        width:"274px",
        background:"linear-gradient(90deg,rgba(0, 39, 89, 1), rgba(75, 108, 183, 1))",
        display:"flex",
        justifyContent:"center",
        borderRadius:"8px",
        alignItems:"center",
        margin:theme.spacing(2),
        [theme.breakpoints.between('lg','xl')]: {
            height:"324px",
            width:"234px",
            margin:theme.spacing(3),
         },
         [theme.breakpoints.between('sm','lg')]: {
            height:"284px",
            width:"224px",
            margin:theme.spacing(2),
         },
      },
      ourTeamsDesc:{
        position:"relative",
        display:"flex",
        flexDirection:"column",
        textAlign:"left",
        gap:theme.spacing(2),
        padding:theme.spacing(0,0,3,1),
      },
      ourTeamImg:{
        width:"100%",
        height:"262px",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        [theme.breakpoints.between('sm','md')]: {
            height:"234px",
         },
         [theme.breakpoints.between('md','lg')]: {
            height:"200px",
         },
      },
      ourMentor:{
        display:"flex",
        flexDirection:"column",
        gap:theme.spacing(2),
        alignItems:"center",
        flexWrap:"wrap",
        width:"176px",
        textAlign:"center",
      },
      ourMentorImg:{
        width:"173px",
        height:"173px",
      },
      linkedinIcon:{
        position: 'absolute',
        bottom: 12 ,
        right:0,
        padding:theme.spacing(1.25),
        [theme.breakpoints.up('md')]:{
            bottom:20
        },
        [theme.breakpoints.up('lg')]:{
            bottom:27.5
        }
      },
}))


function ImageInfo({ src, name, title, linkedinUrl, isMentor }) {
    const classes = useStyles();
    
    return (
        <Box className={`${!isMentor ? `${classes.borderOverlay} ` : ``}`} >
        <Box className={`${!isMentor ? `${classes.ourTeams} ${classes.border}` : 
            `${classes.ourMentor}`}`}>
        <Box
            className={isMentor ? `${classes.ourMentorImg} ${classes.containerBox}`  : 
            `${classes.ourTeamImg} ${classes.containerBox}`}
            style={{ backgroundImage: `url(${src})` }}
        >
        </Box>
            <Box
               className={`${!isMentor ? `${classes.ourTeamsDesc} ` : `${classes.ourMentor}`}`}
            >
                <Typography variant='h6-medium' color={isMentor ? 'shades.clr-white-900' : ""}>
                    {name}
                </Typography>
                <Typography variant='body01-regular' color={isMentor ? 'shades.clr-white-900' : ""}>
                    {title}
                </Typography>
                {(!isMentor) &&
                    <IconButton
                        className={`${classes.linkedinIcon}`}
                        href={linkedinUrl}
                        target='_blank'
                    >
                        <img 
                          height={34} 
                          width={34} alt="Linkedin" 
                          src='https://assets.languify.in/images/Linkedin.svg'/>
                     </IconButton>
                    }
            </Box>
        </Box>
        </Box>
    );
}

export default ImageInfo;
