import { CircularProgress, Typography, Dialog, DialogContent } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import makeStyles from "@mui/styles/makeStyles";


const useStyles = makeStyles(theme => ({
  animationContainer: {
    height: '430px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: 730,
    transition: 'all 0.5s ease-in-out',
    [theme.breakpoints.down('sm')]: {
      width: "80dvw", 
    },
  },
  progressBox: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progress: {
    color: theme.palette.tertiary['clr-300'],
    borderWidth: '2px',
    transition: 'all 1s ease-in-out',
  },
  progressText: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  movingBoxes: {
    width: '100%',
    maxHeight: '150px',
    display: 'flex',
    overflow: 'hidden',
  },
  box: {
    display: 'flex',
    width: '100%',
    alignSelf: 'end',
    transition: 'all 1s ease-in',
  }
}));

const boxes = [
  { color: '#FFF6E5', h1: '220px', h2: '80px' },
  { color: '#DEF7FC', h1: '50px', h2: '150px' },
  { color: '#DFD8FD', h1: '100px', h2: '230px' },
  { color: '#DEF7FC', h1: '200px', h2: '80px' },
  { color: '#FFF6E5', h1: '50px', h2: '180px' },
  { color: '#DEF7FC', h1: '220px', h2: '110px' },
  { color: '#DFD8FD', h1: '100px', h2: '220px' },
];

function CircularProgressWithLabel({ ...props }) {
  const classes = useStyles();
  const [size, setSize] = React.useState(props.size ?? 150);
  const [thickness, setThickness] = React.useState(3);
  const radius = (size - thickness) / 2;

  React.useEffect(() => {
    if (props.value <= 0) {
      setSize(230);
    } else {
      setSize(150);
    }
  }, [props]);

  return (
    <Box className={classes.progressBox} sx={{ marginTop: props.value > 0 ? '70px' : '0px' }}>
      <Box position="relative" display="inline-flex">
        <svg width={size} height={size} style={{ transition: 'all 1s ease-in-out' }}>
          <circle
            cx={size / 2}
            cy={size / 2}
            r={radius}
            fill="none"
            stroke="#d3d3d3"
            strokeWidth={thickness}
            strokeDasharray="2, 8"
            style={{ transform: "rotate(-90deg)", transformOrigin: "50% 50%" }}
            className={classes.progress}
          />
        </svg>
        <CircularProgress variant="determinate"
          className={classes.progress}
          {...props} style={{ position: 'absolute', height: size, width: size }}
          thickness={1}
        />
      </Box>
      <Box className={classes.progressText}>
        <Typography variant="caption" component="div" color="tertiary.clr-300" fontSize="20px">
          {
            props.value >= 90 ?
              <span>Get Ready</span>
              :
              props.value === 0
                ? <span>All the Best!</span>
                : <span style={{ fontSize: '30px' }}>{Math.round(props.value / 25)}</span>
          }
        </Typography>
      </Box>
    </Box>
  );
}

const CircularProgressCountDown = ({ proceedToTest, onDialogClose }) => {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(100);

  React.useEffect(() => {
    let delay = 500;
    let intervalDuration = 1200;

    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress < 0 ? 100 : prevProgress - 25));

      setTimeout(() => {
      }, delay);
    }, intervalDuration + delay);

    return () => {
      clearInterval(timer);
    };
  }, []);

  React.useEffect(() => {
    if (progress < 0) {
      proceedToTest();
      onDialogClose();
    }
  }, [progress, onDialogClose, proceedToTest]);

  return (
    <Dialog open={true} onClose={onDialogClose} maxWidth="md">
      <DialogContent
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          backgroundColor: 'transparent',
          padding: 0,

        }}>
      
        <Box height="100%" display="flex" justifyContent="center" alignItems="center">
          <Box
            className={classes.animationContainer}
            sx={{ justifyContent: progress > 0 ? 'space-between' : 'center' }}
          >
            <CircularProgressWithLabel value={progress} />
            {progress > 0 && (
              <Box className={classes.movingBoxes}>
                {boxes.map((data, i) => (
                  <Box className={classes.box} key={i}
                    style={{
                      backgroundColor: data.color,
                      minHeight: Math.round(progress / 25) % 2 === 0 ? data.h1 : data.h2,
                    }}
                  >
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default CircularProgressCountDown;
