import Client from "./_client";

export const finishInterviewAttempt = async (attemptId) => {
  if (!attemptId) return;

  const result = await new Client({
    path: `/interviewAttempt/${attemptId}/finish-interactive-demo`,
    payload: {
      finishedAt: Date.now(),
    },
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! unable to finish the interview. Please try again"
    );
  }
  return result?.data;
};
