/* eslint-disable no-undef */
import React from 'react'
import { Box , Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';

import AppShell from 'components/AppShell'
import ClientsSection from 'components/ClientsSection'
import HeroSection from 'components/HeroSection'
import IssuesSection from 'components/UseCase/IssuesSection'
import SolutionSection from 'components/SolutionSection'
import TestimonialSection from 'components/TestimonialSection'
import SoftwareSection from 'components/SoftwareSection'
import Faq from 'components/Faq'
import Demo from 'components/Demo';

const useStyles = makeStyles((theme) => ({
    spanHead: {
        display: 'inline-block',
        marginRight: theme.spacing(3),
        background: 'linear-gradient(90.71deg, #8270DB 39.46%, #003E8C 99.73%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundClip: 'text',
        textFillColor: 'transparent',
    },
    typography:{
        fontFamily:theme.typography['h1-medium'].fontFamily,
        fontSize: "57px",
        fontWeight: 500,
        lineHeight: "68.4px",
        textAlign: "left",
        [theme.breakpoints.down("md")]: {
           fontSize: "48px",
           lineHeight: "59.52px",
          }
    }
}));

const items = [
    {
      title: 'Pointless homework',
      subText: 'You know students are copying homework from their friends or ChatGPT.',
      cardText: `They're wasting time since they're not learning, and your time is wasted in 
      grading the copied work.`
    },
    {
        title:'Difficulty in 1:1 Interaction',
        subText:'The classroom environment does not address all student needs',
        cardText:`Every student has different requirement, which is just not addressed in a 
        classroom environment.`
    },
    {
        title:`Can't Track Progress`,
        subText:`Maybe the students are learning, but how much? Where are they stuck?`,
        cardText:`There just isn't any way to track how each student is doing, who needs help, 
        and where.`
    }
  ];

const solutionItems = [
    {
        img : 'https://assets.languify.in/images/Mask.png',
        title: 'Homework that leads to learning',
        subText: `Have students answer questions verbally - which leads to better learning and 
        retention also helps them in improving their communication skills.`
    },
    {
        img : 'https://assets.languify.in/images/computer.png',
        title:'Each Students Gets 1:1 Attention',
        subText:`Each student can practice 1:1 on with AI, where they are given personalised 
        feedback and suggestions based on their performance & needs.`
    },
    {
        img : 'https://assets.languify.in/images/search.png',
        title:`Know Every Student's Exact Progress`,
        subText:`Track how students are performing in each of their assessments, whether scores are 
        increasing or not, and where exactly they're having most difficulty.`
    }
]

const Education = () => {
    const classes = useStyles();

    const elementToScrollRef = React.useRef(null);
    const location = useLocation();
  
    const showDemo = React.useCallback(() => {
      if (elementToScrollRef.current) {
        elementToScrollRef.current.scrollIntoView({ behavior: "smooth",block: "center", inline: "nearest" });
      }
    }, []);
  
    React.useEffect(() => {
      if (location.hash === '#demo') showDemo();
    }, [location]);

    const title = (
        <Box>
            <Typography className={classes.typography} >
            Turn lessons into conversations with&nbsp;
                <span className={classes.spanHead}>
                 Teach & Train
                </span>
            </Typography>       
        </Box>
    );

  return (
   <AppShell>
        <HeroSection 
            title={title} 
            img={["https://assets.languify.in/images/hands.png" ]}
            subTitle=' Conversational AI for Interview preparation' 
            onExperienceLive={showDemo}
        />
        <ClientsSection />
        <IssuesSection items={items}/>
        <SolutionSection items={solutionItems}/>
        <Demo elementToScrollRef={elementToScrollRef} />
        <TestimonialSection />
        <SoftwareSection />
        <Faq />
   </AppShell>
  )
}

export default Education
