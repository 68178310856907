import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLocation } from "react-router-dom";

import LinearGradient from "./LinearGradient";
import React from "react";
import VideoPlayer from "./VideoPlayer";

const useStyles = makeStyles((theme) => (
{
    container:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(7),
    },
    videoContainer:{
        display: 'flex',
        justifyContent: 'center',
    },
    title: {
       ...theme.typography['h4-medium'],
         color: theme.palette.primary.main,
    },
}
));

export function ProductDemoVideo({ link, thumbnail }) {
    const classes = useStyles();
    const location = useLocation();
    const isInprep = React.useMemo(() => (location.pathname === '/inprep') ? true : false, []);


    return (
        <Box className={classes.container}>
            <Typography className={classes.title}>
            {
                isInprep ? 
                <>
                AI capablities &nbsp;
                <span style={{ color: '#2DABFF' }}>Demo</span>
                </>
                : 
                <>
                Our Product
                </>

            }
            </Typography>
            <LinearGradient>
                <Box className={classes.videoContainer}>
                    <VideoPlayer link={link} thumbnail={thumbnail}/>
                </Box>
            </LinearGradient>
        </Box>
    );
}