import React from 'react';
import AppShell from '../components/AppShell';
import ImagesFlexbox from '../components/ImagesFlexbox';
import VideoPlayer from 'components/VideoPlayer';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import { Box } from '@mui/material';
import {animation} from "../utils/index";
import {Team,Mentor} from "../components/Constant"

const ourMission=[
  {
    url:"https://assets.languify.in/images/OurMisson1.png",
    text:"Reaching 1000s of educational institutes and helping them use AI to upgrade student experience."
  },
  {
    url:"https://assets.languify.in/images/OurMission2.png",
    text:"Increasing the learning outcomes of the academic programs up to avg. 50% wrt core concepts & communication skills."
  },
  {
    url:"https://assets.languify.in/images/OurMission3.png",
    text:"Languify AI becoming the first thought of any educator to act as a teacher assistant and provide 1-on-1 learning care at scale, affordably."
  },

]



const useStyles = makeStyles((theme) => ({
  gradientTop:{
    width:"100%",
    height:"40px",
    top:0,
    left:0,
    position:"absolute",
    background: "linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)",
    [theme.breakpoints.down("700")]: { 
      display:"none"
     }
  },
  gradientBottom:{
    position:"absolute",
    height:"40px",
    width:"100%",
    bottom:0,
    left:0,
    background: "linear-gradient(0deg, rgba(255, 255, 255, 0) 100%, #FFFFFF 0%)",
    [theme.breakpoints.down("700")]: { 
      display:"none"
     }
  },
  marginBottom:{
    marginBottom:theme.spacing(3)
  },
  paddingBottom:{
    paddingBottom:theme.spacing(5)
  },
  paddingTop:{
    paddingTop:theme.spacing(10)
  },
  aboutus: {
    textAlign: 'center',
    width: '100%',
    display:"flex",
    flexDirection:"column",
    position:"relative",
    animationName:`$enter`,
    animationDuration:"300ms",
    animationTimingFunction:"ease",
    marginTop:theme.spacing(5),
    marginBottom:theme.spacing(5),
    padding:theme.spacing(0,37.5),
    [theme.breakpoints.down('lg')]:{
      padding:theme.spacing(0,15),
    },
    [theme.breakpoints.down('md')]:{
      padding:theme.spacing(0,10),
    },
    [theme.breakpoints.down('sm')]:{
      padding:theme.spacing(0,5),
    }
  },
  aboutusDesc: {
    textAlign: 'center',
    width: '60%',
    display:"flex",
    flexDirection:"column",
    margin:"auto",
    alignItems:"center",
    animationName:`$enter`,
    animationDuration:"300ms",
    animationTimingFunction:"ease",
    [theme.breakpoints.down('sm')]: { width: '100%', boxSizing: "border-box",
      paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
     }
  },
  aboutusImg:{
    width:"89.59px",
    height:"51.45px"
  },
  aboutusImgLeft:{
    position:"absolute",
    left:"24px",
    top:"24px",
    animationName:`$enterLeft`,
    animationDuration:"500ms",
    animationTimingFunction:"ease",
    [theme.breakpoints.down('sm')]: { position: "inherit",
      alignSelf: "flex-start",
      marginTop:theme.spacing(4),
     }
  },
  aboutusImgRight:{
    position:"absolute",
    bottom:"-35px",
    right:"24px",
    animationName:`$enterRight`,
    animationDuration:"500ms",
    animationTimingFunction:"ease",
    [theme.breakpoints.down('sm')]: { position: "inherit",
      alignSelf: "flex-end",
      marginBottom:theme.spacing(3),
     }
  },
  aboutusPadding:{
    lineHeight:"160%",
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
     }
  },
  aboutusDot:{
    animationName:`$enterTop`,
    animationDuration:"500ms",
    animationTimingFunction:"ease",
  },
  ourmission: {
    display:"flex",
    width:"100%",
    flexDirection:"column",
    alignItems:"center",
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(5),
    padding: theme.spacing(15,37.5),
    animationName:`$enterBottom`,
    animationDuration:"500ms",
    position:"relative",
    animationTimingFunction:"ease",
    gap:theme.spacing(10),
    background:`url("https://assets.languify.in/images/gridFrame.png")`,
    [theme.breakpoints.down('lg')]:{
      padding:theme.spacing(15),
    },
    [theme.breakpoints.down('md')]:{
      padding:theme.spacing(15,10),
    },
    [theme.breakpoints.down('700')]:{
      padding:theme.spacing(6,10),
    },
    [theme.breakpoints.down('sm')]:{
      padding:theme.spacing(6,5),
    }
  },
  ourmissionCards: {
    display:"flex",
    flexDirection:"row",
    flexWrap:"wrap",
    justifyContent:"center",
    gap:theme.spacing(5),
  },
  ourmissionCardOverlay: {
    height:"194px",
    width:"100%",
    backgroundRepeat: 'no-repeat',
    backgroundBlendMode: "luminosity",
  },
  ourmissionCard: {
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    width: "353px",
    height: "416px",
    borderRadius:"8px",
    textAlign:"left",
    padding:theme.spacing(1),
    gap:theme.spacing(5),
    position:"relative",
    backgroundImage: `url("https://assets.languify.in/images/Cotton.png")`,
    [theme.breakpoints.between("sm","xl")]: {
      width: "300px",
      height: "400px",
    },
    [theme.breakpoints.down('sm')]:{
      width: "100%",
      height: '380px'
    }
  },
  itemsContainer: {
    width: '60%',
    [theme.breakpoints.down('sm')]: { width: '90%', }
  },
  ourCulture:{
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    width:"100%",
    position:"relative",
    marginTop:theme.spacing(15),
    marginBottom:theme.spacing(5),
    padding:theme.spacing(0,37.5),
    [theme.breakpoints.down('lg')]:{
      padding:theme.spacing(0,15),
    },
    [theme.breakpoints.down('md')]:{
      padding:theme.spacing(0,10),
    },
    [theme.breakpoints.down('sm')]:{
      padding:theme.spacing(0,5),
    }
  },
  ourCultureImgDimension:{
      height:"400px",
      width:"150px",
      [theme.breakpoints.down('lg')]: {height:"300px",
        width:"100px", },
      [theme.breakpoints.down('sm')]: {height:"200px",
        width:"70px", }
  },
  ourCultureImgLeft:{
   position:"absolute",
   left:0,
   top:0,
   opacity:0,
   transform:"translateX(-50px)",
   transition:"all 500ms ease",
   [theme.breakpoints.down('sm')]: {
    top: "-80px"}
  },
  ourCultureImgRight:{
    position:"absolute",
    right:0,
    opacity:0,
    transform:"translateX(100%)",
    transition:"all 500ms ease",
    bottom: "-16px",
    [theme.breakpoints.down('sm')]: {bottom: "-160px" }
  },
  ourCultureVideo:{
    display:"flex",
    justifyContent:"center",
    width:"100%" 
  },
  ourTeam:{
    backgroundColor:theme.palette.shades['clr-white-900'],
    zIndex:1,
    marginTop:theme.spacing(9),
    marginBottom:theme.spacing(5),
    display:"flex",
    flexDirection:"column",
    width:"100%",
    justifyContent:"center",
    textAlign:"center",
    padding:theme.spacing(0,37.5),
    [theme.breakpoints.down('lg')]:{
      padding:theme.spacing(0,15),
    },
    [theme.breakpoints.down('md')]:{
      padding:theme.spacing(0,10),
    },
    [theme.breakpoints.down('sm')]:{
      padding:theme.spacing(0,5),
      marginTop: theme.spacing(15)
    }
  },
  ourMentor:{
    textAlign:"center",
    background:`linear-gradient(180deg, 
    rgba(0, 0, 0, 0.2) 0%,rgba(0, 0, 0, 0) 10%),
    url(https://assets.languify.in/images/ourmentor_bg.png)`,
    display:"flex",
    flexDirection:"column",
    gap:theme.spacing(8),
    width:"100%",
    marginTop:theme.spacing(5),
    padding:theme.spacing(5,37.5),
    [theme.breakpoints.down('lg')]:{
      padding:theme.spacing(5,15),
    },
    [theme.breakpoints.down('md')]:{
      padding:theme.spacing(5,10),
    },
    [theme.breakpoints.down('sm')]:{
      padding:theme.spacing(5),
    }
  },
  '@keyframes enterLeft': {
    'from': {
      transform: 'translateX(-50px)',
      opacity: 0,
    },
    'to': {
      transform: 'translateX(0%)',
      opacity: 1,
    },
  },
  tranfromFromBottom:{
    opacity:0,
    transform:"translateY(50px)",
    transition:"all 500ms ease",
    transitionDelay: "300ms",
  },
  enterFromX:{
    opacity:1,
    transform:"translateX(0px)",
  },
  enterFromY:{
    opacity:1,
    transform:"translateY(0px)",
  },
  '@keyframes enterTop': {
    'from': {
      transform: 'translateY(-10%)',
      opacity: 0,
    },
    'to': {
      transform: 'translateY(0%)',
      opacity: 1,
    },
  },
  '@keyframes enterBottom': {
    'from': {
      transform: 'translateY(10%)',
      opacity: 0,
    },
    'to': {
      transform: 'translateY(0%)',
      opacity: 1,
    },
  },
  '@keyframes enterRight': {
    'from': {
      transform: 'translateX(50px)',
      opacity: 0,
    },
    'to': {
      transform: 'translateX(0%)',
      opacity: 1,
    },
  },
  '@keyframes enter': {
    'from': {
      opacity: 0,
    },
    'to': {
      opacity: 1,
    },
  },
  header: {
    [theme.breakpoints.down('sm')]: {
      ...theme.typography['h3-medium'],
      fontSize: '33px !important'
    }
  }
}));


const AboutSection=()=>{
  const classes = useStyles();

  return(
    <Box className={`${classes.aboutus}`}>
      <Box className={classes.aboutusDesc}>
        <img 
          className={classes.aboutusDot} 
          src='https://assets.languify.in/images/aboutUs_dot.png'
          alt="dot"
          width={400} 
          height={50}
        />
        <img 
          src='https://assets.languify.in/images/Group.png'
          className={`${classes.aboutusImgLeft} ${classes.aboutusImg} `}
          alt="group"
        />
        <Typography variant='h2-semiBold' color='neutral.clr-800' 
          className={`${classes.marginBottom} ${classes.header}`}
        >
          About us
        </Typography>
        <Typography variant='h6-medium' className={classes.aboutusPadding} color="neutral.clr-600" >
          At Languify, we revolutionize career success.
          Our AI-led platform, backed by experts from prestigious institutions
          such as IIT-Bombay and Carnegie Mellon University helps individuals
          ace interviews, enhance communication skills, and master core concepts.
        </Typography>
        <img 
          className={`${classes.aboutusImgRight} ${classes.aboutusImg}`} 
          src='https://assets.languify.in/images/Group.png'
          alt="img"
        />
      </Box>
    </Box>
  )
}

const OurMission=()=>{
  const classes = useStyles();
  return(
    <Box className={`${classes.ourmission}`}>
      <Box className={classes.gradientTop}>
      </Box>
      <Box className={classes.gradientBottom}>
      </Box>
      <Typography variant='h2-semiBold' color='neutral.clr-800' className={classes.header}>
        Our Mission
      </Typography>
      <Box className={classes.ourmissionCards}>
        {
          ourMission.map((item,index) => (
            <Box key={index} className={`${classes.ourmissionCard} `}>
              <Box style={{
                  backgroundImage: `url("${item.url}"),
                    url("https://assets.languify.in/images/Cotton.png")`
                }} className={classes.ourmissionCardOverlay}
              />
              <Typography px={3} variant='h6-medium' color="default.main" >
                {item.text}
              </Typography>
            </Box>
          ))
        }
      </Box>
    </Box>
  )
}

const OurCulture=()=>{
  const classes = useStyles();

  React.useEffect(()=>{
    const handleScroll = () => {
        animation(`.${classes.ourCultureImgLeft}`, `${classes.enterFromX}`);
        animation(`.${classes.ourCultureImgRight}`, `${classes.enterFromX}`);
        animation(`.${classes.tranfromFromBottom}`, `${classes.enterFromY}`,20);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  },[])


  return(
    <Box className={`${classes.ourCulture } ${classes.paddingBottom}`}>
      <img 
        className={`${classes.ourCultureImgLeft} ${classes.ourCultureImgDimension}`}
        src="https://assets.languify.in/images/Group+3626.png"
        alt="group"
      />
      <Typography className={`${classes.tranfromFromBottom} ${classes.header}`} variant='h2-semiBold'
        color='neutral.clr-800'>
        Our Culture
      </Typography> <br />
      <Box className={`${classes.tranfromFromBottom} ${classes.ourCultureVideo}`}>
          <VideoPlayer 
            link={"https://assets.languify.in/video/team_updates.mp4"}
            thumbnail={"https://assets.languify.in/images/team_thumbnail.webp"}
          />
      </Box>
      <img 
        className={`${classes.ourCultureImgRight} ${classes.ourCultureImgDimension}`} 
        src="https://assets.languify.in/images/Group+3626.png"
        alt="group"
      />
    </Box>
  )
}

const OurTeam=()=>{
  const classes=useStyles();
  React.useEffect(()=>{
    const handleScroll = () => {
        animation(`.${classes.tranfromFromBottom}`, `${classes.enterFromY}`,20);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  },[])
  return(
    <Box className={`${classes.ourTeam} ${classes.tranfromFromBottom}`}>
      <Typography className={` ${classes.paddingBottom} ${classes.tranfromFromBottom} ${classes.header}`} 
      variant='h2-semiBold' color='neutral.clr-800'>
        Our Team
      </Typography>
      <ImagesFlexbox imagesInfo={Team} width={'100%'} />
    </Box>
  )
}

const OurMentor=()=>{
  const classes=useStyles();
  React.useEffect(()=>{
    const handleScroll = () => {
        animation(`.${classes.tranfromFromBottom}`, `${classes.enterFromY}`,20);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  },[])
  return(
    <Box className={`${classes.ourMentor} ${classes.tranfromFromBottom} ${classes.paddingTop}`}>
      <Typography variant='h2-semiBold' color='shades.clr-white-900' className={classes.header}>
        Our Mentors
      </Typography>
      <ImagesFlexbox width={"100%"} imagesInfo={Mentor} isMentor={true} />
    </Box>
  )
}


function AboutUs() {
 
  return (
    <AppShell>
      <AboutSection/>
      <OurMission/>
      <OurCulture/>
      <OurTeam/>
      <OurMentor/>
    </AppShell>
  );
}

export default AboutUs;
