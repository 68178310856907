import React from "react";
import ProductBreakdowns from "components/ProductBreakDowns";

const Steps = [
    {
        label: 'Mock',
        title: 'Practice',
        about: 'Provide unlimited access to curriculum-based verbal assessments to your students',
        video: "https://assets.languify.in/gifs/practice_gif.mp4",
        url: '/',
        reverse: true,
        discriptions: [
            'Students can practice core concepts and communication skills with adaptive AI that generates follow-up questions  based on feedback',
            'Empower your students to practice anytime, anywhere, and unlimited times till they achieve perfection',
            'Get real interview questions from 100+ companies & job profiles with a total of 1000+ questions to practice from',
        ]
    },
    {
        label: 'Feedback',
        title: 'Feedback',
        about: 'Give students the 1 - on - 1 attention they deserve through our personalized feedback on performance',
        video: "https://assets.languify.in/gifs/feedback_gif.mp4",
        url: process.env.REACT_APP_INPREP_URL,
        newPage: true,
        discriptions: [
            'Identify strengths and weakness and receive actionable feedback to improve',
            'Track your progress on core concepts & communication based on 25+ critical parameters',
            'Receive personalized resources to improve on your weak areas',
        ]
    },
    {
        label: 'Improve',
        title: 'Improve',
        about: 'Help students become master communicators through gamification and micro-learning resources',
        video: "https://assets.languify.in/gifs/improve_gif.mp4",
        url: process.env.REACT_APP_INPREP_URL,
        newPage: true,
        reverse: true,
        discriptions: [
            'Distraction, judgment & peer-pressure free environment to improve with AI',
            'Gamified mock interviews with AI for you to practice 100s of times with ease',
            'Access to 1000+ learning materials to understand the right topics for acing the interview',
        ]
    },
    {
        label: 'Analyze',
        title: 'Analyze',
        about: 'Help students become master communicators through gamification and micro-learning resources',
        video: "https://assets.languify.in/gifs/improve_gif.mp4",
        url: process.env.REACT_APP_INPREP_URL,
        newPage: true,
        discriptions: [
            'Track performance of 1000s of students across 100s of mock interviews based on 25+ interview parameters',
            'Conduct data led intervention to help TPOs improve students on their precise weakness',
            'Filter and provide pre-assessed students as per skills level to match with company’s requirements',
        ]
    },
];

export default function ProductBreakdown({ onExperienceLive }) {
    return(
        <ProductBreakdowns onExperienceLive={onExperienceLive} Steps={Steps}/>
    );
}