import Client from "./_client";

export const generateDemoQuestion = async (data) => {
    const url = `/interviewQuestion/generate`;

    const result = await new Client({
        path: url,
        payload: data
    }).post();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to generate the question. Please try again."
        );
    }

    return result.data;
};
