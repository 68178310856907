/* eslint-disable no-undef */
import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';

import AppShell from 'components/AppShell';
import ClientsSection from 'components/ClientsSection';
import HeroSection from 'components/HeroSection';
import IssuesSection from 'components/UseCase/IssuesSection';
import SolutionSection from 'components/SolutionSection';
import TestimonialSection from 'components/TestimonialSection';
import SoftwareSection from 'components/SoftwareSection';
import Faq from 'components/Faq';
import Demo from 'components/Demo';

const useStyles = makeStyles((theme) => ({
    spanHead: {
        display: 'inline-block',
        background: 'linear-gradient(90.71deg, #8270DB 39.46%, #003E8C 99.73%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundClip: 'text',
        textFillColor: 'transparent',
    },
    typography:{
        fontFamily:theme.typography['h1-medium'].fontFamily,
        fontSize: "57px",
        fontWeight: 500,
        lineHeight: "68.4px",
        textAlign: "left",
        [theme.breakpoints.down("md")]: {
          fontSize: "48px",
          lineHeight: "59.52px",
        }
    }
}));

const items = [
    {
      title: 'Students Poor Performance',
      subText: `Students fail to clear the company Interviews due to bad performance`,
      cardText: `Even after the college puts in effort to bring recruiters, most students are not 
      able to clear the interview and end up wasting that effort.`
    },
    {
        title:'No outcomes of Trainings',
        subText:'Conducting different trainings & workshops are not working!',
        cardText:`More than 80% students don't pay attention during training, which means placement 
        rate doesn't increase.`
    },
    {
        title:`Not enough 1:1 attention`,
        subText:`Every student doesn’t get 1:1 attention for the Interview preparation`,
        cardText:`Students need at least 10-15 mock interviews to be confident and increase their 
        chances. How will you deliver that?`
    }
  ];

  const solutionItems = [
    {
        img : 'https://assets.languify.in/images/Mask.png',
        title: 'Unlimited mock interviews - at no extra cost',
        subText: `Students can practice as many times as they want until they become confident and 
        are able to respond properly.`
    },
    {
        img : 'https://assets.languify.in/images/computer.png',
        title:'Real past interview questions',
        subText:`Students practice real questions that companies have asked in the past interviews.`
    },
    {
        img : 'https://assets.languify.in/images/feedback.png',
        title:`Objective feedback & tracking`,
        subText:`Get detailed objective reports on student's performance, and track how they have 
        improved over time.`
    }
]

const InterviewPrep = () => {
    const classes = useStyles();

    const elementToScrollRef = React.useRef(null);
    const location = useLocation();
  
    const showDemo = React.useCallback(() => {
      if (elementToScrollRef.current) {
        elementToScrollRef.current.scrollIntoView({ behavior: "smooth",block:"center" });
      }
    }, []);
  
    React.useEffect(() => {
      if (location.hash === '#demo') showDemo();
    }, [location]);

    const title = (
        <Box>
            <Typography className={classes.typography}>
                <span className={classes.spanHead}>
                In-Prep Pathway&nbsp;
                </span>
                offers personalized feedback for your students' interview preparation
            </Typography>
        </Box>
    );

    return (
        <AppShell>
            <HeroSection 
                title={title} 
                img={["https://assets.languify.in/images/hand_shake.png" ]}
                subTitle=' Conversational AI for Interview preparation' 
                onExperienceLive={showDemo} 
            />
            <ClientsSection />
            <IssuesSection items={items} />
            <SolutionSection items={solutionItems} />
            <Demo elementToScrollRef={elementToScrollRef} />
            <TestimonialSection />
            <SoftwareSection />
            <Faq />
        </AppShell>
    );
};

export default InterviewPrep;
