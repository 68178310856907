import React from 'react'
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    imageContainer: {
        height: '407px',
        width: '407px',
        position: 'relative',
        [theme.breakpoints.down("1400")]: {
          height: '357px',
          width: '357px',
        },
        [theme.breakpoints.down("1000")]: {
          display: 'none',
        }
      },
      imageContainerB2C:{
        position: 'relative',
        width: "502px",
        height: "100%",
        [theme.breakpoints.down('1400')]:{
          width: "402px",
        },
        [theme.breakpoints.down('lg')]:{
          width: "350px",
        },
        [theme.breakpoints.down('1024')]:{
          display:"none"
        }
      },
      borderTop: {
        position: 'absolute',
        backgroundColor: theme.palette.warning['clr-300'],
        borderRadius:"4px", 
        width: '37%',
        height: '5px',
        top: 0,
        right: 0,
      },
      borderRight: {
        position: 'absolute',
        backgroundColor: theme.palette.warning['clr-300'], 
        borderRadius:"4px",
        width: '5px', 
        height: '37%',
        top: 0,
        right: 0,
      },
      borderBottom: {
        position: 'absolute',
        backgroundColor: theme.palette.warning['clr-300'], 
        borderRadius:"4px",
        width: '37%',
        height: '5px', 
        bottom: 0,
        left: 0,
      },
      borderLeft: {
        position: 'absolute',
        backgroundColor: theme.palette.warning['clr-300'], 
        borderRadius:"4px",
        width: '5px', 
        height: '37%',
        bottom: 0,
        left: 0,
      },
      imageBox:{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        objectFit: 'cover', 
        overflow:"hidden",
        boxShadow: '0px 0px 32px 0px #00000059',
        border:'1px solid',
        borderColor: theme.palette.shades['clr-white-900'],        
        borderRadius: '8px',
        [theme.breakpoints.down("1400")]: {
          height: '307px',
          width: '307px',
        },
      },
      imageBoxB2x:{
        height: '100%',
        width: '502px',
        overflow:"visible",
        display:"flex",
        justifyContent:"center",
        marginLeft:"50px",
        [theme.breakpoints.down('1024')]:{
          display:"none",
        }
      },
      images:{
        display:"flex",
        flexDirection:"row",
        gap:theme.spacing(5),
        margin:theme.spacing(5),
      },
      image:{
        objectFit: 'cover',
      },
      imageB2c: {
        objectFit: 'cover', 
        animation:"$moveY 3s ease infinite",
        [theme.breakpoints.down('1400')]:{
          height:"300px"
        }
      },
      background:{
        position:"absolute",
        background:`url("https:assets.languify.in/images/hero_section_b2c_gradient.png")`,
        top:0,
        left:0,
        backgroundSize: "contain",
        height:"100%",
        width:"100%",
        zIndex:-1,
        backgroundRepeat:"no-repeat",
        [theme.breakpoints.down('lg')]:{
          height:"368px"
        }
      },
      "@keyframes moveY" :{
        "0%": {
          transform: "translateY(-10)"
        },
        "50%": {
          transform: "translateY(40px)"
        },
        "100%": {
          transform: "translateY(-10)"
        }
      }
}))

export function HeroImageTemplate({img, sx = {}, ...props} ) {

    const classes = useStyles();

    return (
        <Box className={classes.imageContainer} style={{...sx }} 
        {...props}>
            <Box className={classes.borderTop} />
            <Box className={classes.borderRight} />
            <Box className={classes.borderBottom} />
            <Box className={classes.borderLeft} />
            <Box className={classes.images}>
              {img.map((value,index)=>(
                <Box  key={index} className={classes.imageBox}>
                  <img src={value} className={classes.image} alt='hero'/>
                </Box>
              ))
              }
            </Box>
      </Box>
    )
}

export function B2CImageTemplate({img, sx = {}, ...props} ) {

  const classes = useStyles();

  return (
      <Box className={classes.imageContainerB2C} style={{...sx }} 
      {...props}>
        <Box className={classes.background}></Box>
          <Box className={classes.images}>
            {img.map((value,index)=>(
              <Box  key={index} className={classes.imageBoxB2x}>
                <img src={value} className={classes.imageB2c} alt='hero'/>
              </Box>
            ))
            }
          </Box>
    </Box>
  )
}