import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

import { IconButton } from './CustomButton';
import { setupIntersectionObserver } from 'utils/observerUtil';


const useStyles = makeStyles((theme) => ({
  softwareContainer: {
    backgroundColor: theme.palette.secondary['clr-700'],
    padding: theme.spacing(21.5, 37.5),
    width: '100%',
    position: 'relative', 
    overflow: 'hidden',
    transition: 'transform 0.4s ease-in-out, opacity 1s ease-in-out',
    transform: 'translateY(50px)',
    opacity: 0,
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(21.5, 15),
    },
    [theme.breakpoints.down("md")]: {
      width: '100%',
      padding: theme.spacing(7, 10),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(7, 5),
    },
  },
  gradientBoxTop: {
    width: '246px',
    height: '246px',
    position: 'absolute',
    background: 'url(https://assets.languify.in/images/Ellipse+Bot.png) no-repeat center center',
    backgroundSize: 'cover',
    top: 0,
    left: 0,
  },
  gradientBoxBottom: {
    width: '246px',
    height: '246px',
    position: 'absolute',
    background: 'url(https://assets.languify.in/images/EllipseTop.png) no-repeat center center',
    backgroundSize: 'cover',
    bottom: 0,
    right: 0,
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(10),
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(5.5),
      padding: theme.spacing(0),
    },
  },
  headContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down("md")]: {
      width: '100%',
      padding: theme.spacing(0, 2.5),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },
  textDecoration:{
    textDecoration:"none"
  }
}));

const SoftwareSection = () => {
  const classes = useStyles();
  const theme = useTheme();

  const [visible, setVisible] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const cleanup = setupIntersectionObserver(containerRef, setVisible, 0.5)

    return cleanup;
  }, []);

  return (
    <Box
      ref={containerRef}
      className={classes.softwareContainer}
      style={{
        transform: visible ? 'translateY(0)' : 'translateY(50px)',
        opacity: visible ? 1 : 0,
      }}
    >
      <Box className={classes.gradientBoxTop}></Box>
      <Box className={classes.contentBox}>
        <Box className={classes.headContent}>
          <Typography variant='h1-xl-medium' color='shades.clr-white-900'>
             It's not our software - it's yours!
          </Typography>
          <Typography variant='h5-semiBold' color='shades.clr-white-900'>
            We provide white labeling facility so you can add your branding on the software when you 
            integrate it into your college or business.
          </Typography>
        </Box>
        <a 
          target='_blank' 
          rel='noreferrer' 
          href={process.env.REACT_APP_GET_IN_TOUCH_TALLY_FORM_URL}
          className={classes.textDecoration}
        >
          <IconButton
          label="Book a call"
          icon="https://assets.languify.in/images/bookCall_White.svg"
          bgColor={theme.palette.primary['clr-500']}
          color={theme.palette.shades['clr-white-900']}
        />
        </a>
      </Box>
      <Box className={classes.gradientBoxBottom}></Box>
    </Box>
  );
}

export default SoftwareSection;
