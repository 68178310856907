import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

import {animation} from "../../utils/index"

const results={
    "40%":"Improved learning outcomes",
   "70%":"Students talk with the AI everyday",
    "50%":"Students talk 2-3 times daily"
}

const useStyles = makeStyles((theme) => ({
    container:{
        backgroundColor: theme.palette.neutral['clr-800'],
        width:"100%",
        position:"relative",
        minHeight:"fit-content",
        padding:theme.spacing(12,50),
        display:"flex",
        opacity:0,
        transform:"translateY(40px)",
        transition:"all 500ms ease",
        justifyContent:"center",
        [theme.breakpoints.down('xl')]:{
            padding:theme.spacing(12,37.5)
        },
        [theme.breakpoints.down('lg')]:{
            padding:theme.spacing(12,15)
        },
        [theme.breakpoints.down('md')]:{
            padding:theme.spacing(12,10)
        },
        [theme.breakpoints.down('sm')]:{
            height:"auto",
            padding:theme.spacing(8,5)
        }
    },
    content:{
        display:"flex",
        flexDirection:"column",
        width:"100%",
        gap:theme.spacing(6),
        justifyContent:"space-around",
    },
    heading:{
        textAlign:"center",
        lineHeight: "48px",
        fontSize: '33px !important',
        fontVariationSettings: "'GRAD' 0, 'XOPQ' 96, 'XTRA' 550, 'YOPQ' 79, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738, 'YTLC' 514, 'YTUC' 712, 'slnt' 0, 'wdth' 100"
    },
    pseduoContainer:{
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'url("https://assets.languify.in/images/gridFrame.png") repeat',
        opacity: 0.02, 
        zIndex: 0
    },
    result:{
        display:"flex",
        flexDirection:"row",
        justifyContent:"space-around",
        gap:theme.spacing(8),
        flexWrap:"wrap",
        [theme.breakpoints.down('sm')]:{
            flexDirection:"column",
        }
    },
    resultItem:{
        display:"flex",
        flexDirection:"column",
        textAlign:"center"
    },
    enterFromY:{
        opacity:1,
        transform:"translateY(0px)",
    },
    title:{
        fontFamily:theme.typography['h1-xl-regular'].fontFamily,
        fontSize:"160px",
        lineHeight:"192px"
    }   ,
    desc:{
        fontSize: "23px",
        lineHeight: "27.6px",
        textAlign: "center"
    }
}));

export default function WithLanguify() {
    const classes = useStyles();

    React.useEffect(()=>{
        const handleScroll = () => {
            animation(`.${classes.container}`, `${classes.enterFromY}`,200);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      },[])

    return (
        <Box className={classes.container}>
            <Box className={classes.pseduoContainer}>
            </Box>
            <Box className={classes.content}>
                <Typography 
                  className={classes.heading} 
                  variant="h2-medium" 
                  color="shades.clr-white-900" 
                >
                    With Languify AI
                </Typography>
                <Box className={classes.result}>
                {Object.entries(results).map(([key, value], index) => (
                    <Box key={index} className={classes.resultItem}>
                        <Typography color="success.clr-300" 
                        className={classes.title}>
                            {key}
                        </Typography>
                        <Typography className={classes.desc} variant="h5-medium"
                            color="success.clr-300">
                            {value}
                        </Typography>
                    </Box>
                ))}
                </Box>
            </Box>
        </Box>
    );
}