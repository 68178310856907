import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Screens from "./screens";

if (process.env.REACT_APP_STAGE === "prod") {
  require("./analytics");
}

ReactDOM.render(
  <React.StrictMode>
    <Screens />
  </React.StrictMode>,
  document.getElementById("root")
);
