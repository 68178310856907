import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";

import {animation} from "../utils/index"
import Chip from "components/Chip";

const useStyles = makeStyles((theme) => ({
    container:{
        background: theme.palette.neutral['clr-900'],
        width:"100%",
        position:"relative",
        minHeight:"520px",
        display:"flex",
        flexDirection:"column",
        gap:theme.spacing(10),
        justifyContent:"space-around",
        padding:theme.spacing(8,37.5),
        [theme.breakpoints.down('lg')]:{
            padding: theme.spacing(8,15)
        },
        [theme.breakpoints.down('md')]:{
            height:"auto",
            padding: theme.spacing(8,10)
        },
        [theme.breakpoints.down('sm')]:{
            height:"auto",
            padding: theme.spacing(8,5)
        }
    },
    content: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        opacity: 0,
        transform: "translateY(100px)",
        transition: "all 500ms ease",
        gap: theme.spacing(54),
        [theme.breakpoints.down('lg')]: {
          gap: theme.spacing(14),
          flexWrap: "wrap",
        },
      },      
    borderBefore:{
        position:"relative",
        overflow:"hidden",
    },
    border:{
        border:'10px dashed',
        borderColor:theme.palette.shades['clr-white-900'],
        position:"absolute",
        top: "-9px",
        bottom: "-9px",
        left: "-9px",
        right: "-9px"
    },
    table:{
        display:"flex",
        alignItems:"center",
        width:"467px",
        flexDirection:"column",
        justifyContent:"center",
        flexWrap:"wrap",
        zIndex:10
    },
    tableLeft:{
        textAlign:"left",
        padding:theme.spacing(14,12,12,12),
        boxSizing:"border-box"
    },
    chip:{
        alignSelf:"center",
        transform:'rotateX(90deg)',
        transition: 'all 500ms ease',
    },
    chipRotate:{
        transform: 'rotateX(360deg)',
    },
    heading:{
        width:"100%",
        padding:theme.spacing(6),
        textAlign:"center",
        boxSizing:"border-box",
        fontStyle:"italic",
    },
    borderBottom:{
        border:'10px dashed',
        borderColor:theme.palette.shades['clr-white-900'],
        position:"absolute",
        top: "-9px",
        bottom: "-9px",
        left: "-9px",
        right: "-9px",
    },
    absoluteIcon:{
        position:"absolute",
        right:15,
        top:15
    },
    compnayLogo:{
        display:"flex",
        flexDirection:"row",
        flexWrap:"wrap",
        alignItems:"center",
        padding:theme.spacing(8),
        justifyContent:"space-around",
        boxSizing:"border-box",
        [theme.breakpoints.down('478')]:{
            '& img': {
                width: '120px',
                height: 'auto',
        },
        }
    },
    enterFromY:{
        opacity:1,
        transform:"translateY(0px)",
    },
    pointer:{
        cursor: 'pointer',
        zIndex:20
    }
}));


export default function BackedBy() {
    const classes = useStyles();
    const theme=useTheme();

    React.useEffect(()=>{
        const handleScroll = () => {
            animation(`.${classes.content}`, `${classes.enterFromY}`,150);
            animation(`.${classes.chip}`, `${classes.chipRotate}`,50);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      },[])

    return (
        <Box className={classes.container}>
            <Chip className={classes.chip} content="We are impact maker"
              sx={{
                borderRadius:"12px",padding:theme.spacing(2,4),display:"flex",
                alignItems:"center",gap:theme.spacing(1)
              }} 
              startIcon={<img src="https://assets.languify.in/images/troffee.svg" alt="Impact" />}
              bgColor={theme.palette.success['clr-100']}
              color={theme.palette.success['clr-700']}
            />
            <Box className={classes.content}>
                <Box className={`${classes.table} ${classes.tableLeft} ${classes.borderBefore}`}>
                <Box className={classes.border}></Box>
                <Typography variant="h4-medium" color="shades.clr-white-900">
                    Norrsken Europe 2023 selected 20 Impact startups out of 2100 applicants 0.8% 
                    success rate
                </Typography>
                <img className={classes.absoluteIcon} alt="dart"
                src='https://assets.languify.in/images/dart.svg'/>
                </Box>
                <Box className={`${classes.table} ${classes.borderBefore}`}> 
                    <Box className={classes.border}></Box>
                    <Box className={`${classes.borderBefore} ${classes.heading}`}>
                    <Box className={classes.borderBottom}></Box>
                    <Typography className={classes.heading} variant="h4-medium" 
                      color="shades.clr-white-900">
                        Backed by
                    </Typography>
                    </Box>
                    <img className={classes.absoluteIcon} alt="stars"
                    src="https://assets.languify.in/images/Stars.svg"/>
                    <Box className={classes.compnayLogo}>
                    <a href="https://www.norrsken.org/" target="_blank"  className={classes.pointer}>
                        <img 
                            src="https://assets.languify.in/images/norrsken.png"
                            alt="norrsken"/>
                    </a>
                    <a href="https://www.titancapital.vc/" target="_blank"  className={classes.pointer}>
                        <img 
                            src="https://assets.languify.in/images/titan.png"
                            alt="titan"/>
                    </a>
                    <a href=" https://smile.group/" target="_blank"  className={classes.pointer}>
                        <img 
                            src="https://assets.languify.in/images/smile_logo.png"
                            alt="smile"/>
                    </a>
                    <a href="https://alsisarimpact.com/" target="_blank"  className={classes.pointer}>
                        <img 
                            src="https://assets.languify.in/images/alsisar.png"
                            alt="alsisar"/>
                    </a>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}