import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const RouteContentMap = {
    "/": {
        title: 'Empowering Educators, Inspiring Minds | AI-Powered EdTech Solutions',
        description: 'Boost Your Platform with our AI-driven Solutions. Enhance assessments, interviews, student communication and unlock the full potential of personalized education.',
        keywords: 'Personalized Education, Student Communication, Customized Learning Journeys, AI-guided Skill Development, AI-driven Solutions, Student Success, Student Engagement',
    },
    "/teach&train": {
        title: 'Personalized Learning Journeys with AI',
        description: 'Offer personalized care with 1-on-1 sessions for massive classrooms. Embrace tailored learning with an AI tutor by your side. Discover the future of education now!',
        keywords: 'Tailored Feedback, Personalized Assessments, 1-on-1 Sessions, Personalize Learning, Learning Potential',
    },
    "/inprep": {
        title: 'Excellence in Interviews with Realistic Mocks',
        description: 'Your Ultimate Mock Interview Solution. Offer realistic experiences, personalized learning, and expert feedback to excel in interviews. Prepare, practice, and succeed!',
        keywords: 'Mock Interview, Personalized Learning, Expert Feedback, Excel in Interviews, Ace Interviews, Land Dream Jobs, AI-powered Mock Interviews',
    },
    "/aboutus": {
        title: 'About Us',
        description: 'Languify, an AI-led platform, revolutionizes career success by enhancing communication skills and mastering core concepts. Backed by experts from IIT-Bombay and Carnegie Mellon University, aim we to reach 1000 businesses, increase learning outcomes, and become the first choice for educators seeking affordable, scalable 1-on-1 learning care.',
        keywords: 'Languify, AI-led platform, career success, communication skills, IIT-Bombay, Carnegie Mellon University, hybrid learning solutions, teacher assistant, 1-on-1 learning, scalable learning.',
    },
    "/usecase/education": {
        title: 'Transform Education with AI-Powered Language Learning | Languify',
        description: 'Revolutionize your educational programs with Languify AI-driven language learning solutions. Engage students with personalized, interactive tools designed to boost their success. Partner with Languify and redefine education today!',
        keywords: ''
    },
    "/usecase/interview-preparation": {
        title: 'Boost Student Success with AI-Driven Interview Prep | Languify',
        description: 'Give your students the edge they need to excel in interviews with Languify AI-enhanced preparation tools. Provide tailored feedback and real-world practice to build their confidence. Partner with Languify to elevate student career readiness!',
        keywords: ''
    },
};

function Head() {
    const location = useLocation();
    const content = RouteContentMap[location.pathname] || {};

    return (
        <Helmet>
            {/* <!-- Primary Meta Tags --> */}
            <title>{ content.title }</title>
            <meta name="title" content={content.title} />
            <meta name="description" content={content.description} />
            <meta name="keywords" content={content.keywords} />

            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://languify.in" />
            <meta property="og:title" content={content.title} />
            <meta property="og:description" content={content.description} />
            <meta property="og:image" 
            content="https://assets.languify.in/images/new-languify-poster.png" />

            {/* <!-- Twitter --> */}
            <meta property="twitter:card" content={content.description} />
            <meta property="twitter:url" content="https://languify.in" />
            <meta property="twitter:title" content={content.title} />
            <meta property="twitter:description" content={content.description} />
            <meta property="twitter:image" 
            content="https://assets.languify.in/images/new-languify-poster.png" />
        </Helmet>
    );
}


export default function HeadWrapper({ Component }) {

    return (
        <>
            <Head />
            <Component />
        </>
    );
}
