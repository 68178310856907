import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

import { setupIntersectionObserver } from 'utils/observerUtil'; 
import { IconButton } from './../CustomButton';
import { PlusTiles } from 'components/Tiles';
import IssuesCard from './IssuesCard';

const links = [
  {
    id: 1,
    name: "Teach & Train",
    link: "/teach&train",
    newPage: false
  },
  {
    id: 2,
    name: "In-prep pathway",
    link: "/inprep",
    newPage: false
  },
];

const useStyles = makeStyles((theme) => ({
  issuesContainer: {
    padding: theme.spacing(15,37.5,11,37.5),
    borderBottom:`2px solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(13),
    position: 'relative',
    opacity: 0,
    transition: 'opacity 0.5s ease',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(15, 15,11,15),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(7, 10),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(7,5),
    },
  },
  visible: {
    opacity: 1,
  },
  issuesCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: theme.spacing(4.5),
    position: 'relative',
    flexWrap:"wrap",
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  topLeftPatternInitial: {
    top: '-45px',
    left: '-45px',
    transform: 'translateX(100px)',
    [theme.breakpoints.down('md')]: {
      left: '-4px',
    },
  },
  topLeftPatternVisible: {
    transform: 'translateX(0)',
  },
  bottomRightPatternInitial: {
    bottom: '-45px',
    right: '-45px',
    transform: 'translateX(-100px)',
    [theme.breakpoints.down('md')]: {
      right: '-4px',
    },
  },
  bottomRightPatternVisible: {
    transform: 'translateX(0)',
  },
  headingContainer: {
    position: 'relative',
    overflow: 'hidden',
    marginBottom: theme.spacing(3),
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 2.5),
    },
  },
  heading: {
    position: 'relative',
    transform: 'translateY(50px)',
    transition: 'transform 0.5s ease',
  },
  headingVisible: {
    transform: 'translateY(0)',
  },
  plusTilesBox: {
    position: 'absolute',
    transition: 'transform 1s ease',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  plusTilesBoxMob: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      transition: 'transform 1s ease',
      display: 'block',
    },
  },
  textDecoration:{
    textDecoration:"none"
  }
}));

const IssuesSection = ({ items }) => {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  const isEducation = React.useMemo(() => (location.pathname === '/usecase/education') ? true : false, [location]);
  const isInterviewpPrep = React.useMemo(() => (location.pathname === '/usecase/interview-preparation') ? true : false, [location]);

  useEffect(() => {
    const cleanup = setupIntersectionObserver(sectionRef, setIsVisible, getIntersectionThreshold());

    return cleanup;
  }, []);

  const getIntersectionThreshold = () => {
    if (window.matchMedia('(max-width: 768px)').matches) {
      return 0.1;
    } else {
      return 0.5;
    }
  };

  return (
    <Box
      className={`${classes.issuesContainer} ${isVisible ? classes.visible : ''}`}
      ref={sectionRef}
    >
      <Box className={classes.headingContainer}>
        <Typography
          variant='h2-medium'
          color='danger.clr-300'
          className={`${classes.heading} ${isVisible ? classes.headingVisible : ''}`}
        >
          We know the issues you're dealing with
        </Typography>
      </Box>
      <Box className={classes.issuesCard}>
        <Box
          className={`${classes.plusTilesBox} ${classes.topLeftPatternInitial} ${
            isVisible ? classes.topLeftPatternVisible : ''
          }`}
        >
          <PlusTiles
            color={theme.palette.shades['clr-black-900']}
            hiddenRowIndex={2}
            hiddenColIndexStart={2}
            hiddenColIndexEnd={4}
          />
        </Box>
        <Box
          className={`${classes.plusTilesBoxMob} ${classes.topLeftPatternInitial} ${
            isVisible ? classes.topLeftPatternVisible : ''
          }`}
        >
          <PlusTiles
            color={theme.palette.shades['clr-black-900']}
            numItems={4}
            hiddenRowIndex={2}
            hiddenColIndexStart={2}
            hiddenColIndexEnd={4}
          />
        </Box>
        <Box
          className={`${classes.plusTilesBox} ${classes.bottomRightPatternInitial} ${
            isVisible ? classes.bottomRightPatternVisible : ''
          }`}
        >
          <PlusTiles
            color={theme.palette.shades['clr-black-900']}
            hiddenRowIndex={0}
            hiddenColIndexStart={0}
            hiddenColIndexEnd={2}
          />
        </Box>
        <Box
          className={`${classes.plusTilesBoxMob} ${classes.bottomRightPatternInitial} ${
            isVisible ? classes.bottomRightPatternVisible : ''
          }`}
        >
          <PlusTiles
            color={theme.palette.shades['clr-black-900']}
            numItems={4}
            hiddenRowIndex={0}
            hiddenColIndexStart={0}
            hiddenColIndexEnd={2}
          />
        </Box>
        {items.map((item, index) => (
          <IssuesCard
            key={index}
            title={item.title}
            subText={item.subText}
            cardText={item.cardText}
            isVisible={isVisible}
          />
        ))}
      </Box>
     <Box>
     <>
          {isEducation && (
            <a
              target={links[0].newPage ? '_blank' : '_self'}
              rel={'noreferrer'}
              href={links[0].link}
              className={classes.textDecoration}
            >
              <IconButton
                label="Explore Teach & Train"
                icon="https://assets.languify.in/images/bookCall_White.svg"
                width="228px"
                bgColor={theme.palette.primary['clr-500']}
                color={theme.palette.shades['clr-white-900']}
              />
            </a>
          )}
          {isInterviewpPrep && (
            <a
              target={links[1].newPage ? '_blank' : '_self'}
              rel={'noreferrer'}
              href={links[1].link}
              className={classes.textDecoration}
            >
              <IconButton
                label="Explore In-Prep Pathway"
                icon="https://assets.languify.in/images/bookCall_White.svg"
                width="253px"
                bgColor={theme.palette.primary['clr-500']}
                color={theme.palette.shades['clr-white-900']}
              />
            </a>
          )}
        </>
     </Box>
    </Box>
  );
};

export default IssuesSection;
