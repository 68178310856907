import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { setupIntersectionObserver } from 'utils/observerUtil'; 
import { IconButton } from './CustomButton';

const links = [
  {
    id: 1,
    name: "Teach & Train",
    link: "/teach&train",
    newPage: false
  },
  {
    id: 2,
    name: "In-prep pathway",
    link: "/inprep",
    newPage: false
  },
];

const useStyles = makeStyles((theme) => ({
  solutionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  solutionBox: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(11),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(7),
    width: '1140px',
    opacity: 0,
    transform: 'translateY(50px)',
    transition: 'opacity 0.5s ease, transform 0.5s ease',
    [theme.breakpoints.down("600")]: {
      padding: theme.spacing(5, 5, 9.5, 5),
    },
  },
  solutionBoxVisible: {
    opacity: 1,
    transform: 'translateY(0)',
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(5),
    justifyContent: 'center',
    alignItems: 'center',
    width: "90%",
    [theme.breakpoints.down("700")]: {
      flexDirection: 'column',
      width: '100%'
    },
  },
  textElements: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    width: '553px',
    [theme.breakpoints.down("md")]: {
      width: '100%',
    },
  },
  imageTop: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    opacity: 0,
    transform: 'translateX(-50px)',
    transition: 'opacity 0.5s ease, transform 0.5s ease',
    [theme.breakpoints.down('lg')]: {
      width: "90px"
    },
    [theme.breakpoints.down('md')]: {
      width: "80px"
    },
    [theme.breakpoints.down("600")]: {
      display: 'none',
    },
  },
  imageTopVisible: {
    opacity: 1,
    transform: 'translateX(0)',
  },
  imageDown: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    opacity: 0,
    transform: 'translateX(50px)',
    transition: 'opacity 0.5s ease, transform 0.5s ease',
    [theme.breakpoints.down('lg')]: {
      width: "90px"
    },
    [theme.breakpoints.down('md')]: {
      width: "80px"
    },
    [theme.breakpoints.down("600")]: {
      display: 'none',
    },
  },
  imageDownVisible: {
    opacity: 1,
    transform: 'translateX(0)',
  },
  headText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    ...theme.typography['h1-semiBold'],
    [theme.breakpoints.down('sm')]: {
      fontSize: '33px !important'
    },
  },
  image: {
    [theme.breakpoints.down("md")]: {
      width: '104px',
    },
  },
  textDecoration: {
    textDecoration: "none"
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      fontSize: '33px'
    },
  },
  subtext: {
    [theme.breakpoints.down("md")]: {
      fontSize: '19px'
    },
  }
}));

const SolutionSection = ({ items }) => {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();

  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  const isEducation = React.useMemo(() => (location.pathname === '/usecase/education') ? true : false, [location]);
  const isInterviewpPrep = React.useMemo(() => (location.pathname === '/usecase/interview-preparation') ? true : false, [location]);

  useEffect(() => {
    const cleanup = setupIntersectionObserver(sectionRef, setIsVisible, 0.5);

    return cleanup;
  }, []);

  return (
    <Box className={classes.solutionContainer}>
      <Box className={`${classes.imageTop} ${isVisible ? classes.imageTopVisible : ''}`}>
        <img src="https://assets.languify.in/images/hexagonal_dotted_up.png" alt="up" />
      </Box>
      <Box
        className={`${classes.solutionBox} ${isVisible ? classes.solutionBoxVisible : ''}`}
        ref={sectionRef}>
        <Box className={classes.headText} color="success.clr-300">
          And we have what you need
        </Box>
        {items.map((item, index) => (
          <Box key={index} className={classes.contentBox}>
            <img src={item.img} alt="mask" className={classes.image} />
            <Box className={classes.textElements}>
              <Typography variant="h3-medium" color="neutral.clr-800" className={classes.title}>
                {item.title}
              </Typography>
              <Typography variant="h6-regular" color="neutral.clr-900" className={classes.subtext}>
                {item.subText}
              </Typography>
            </Box>
          </Box>
        ))}
        <>
          {isEducation && (
            <a
              target={links[0].newPage ? '_blank' : '_self'}
              rel={'noreferrer'}
              href={links[0].link}
              className={classes.textDecoration}
            >
              <IconButton
                label="Explore Teach & Train"
                icon="https://assets.languify.in/images/bookCall_White.svg"
                width="228px"
                bgColor={theme.palette.primary['clr-500']}
                color={theme.palette.shades['clr-white-900']}
              />
            </a>
          )}
          {isInterviewpPrep && (
            <a
              target={links[1].newPage ? '_blank' : '_self'}
              rel={'noreferrer'}
              href={links[1].link}
              className={classes.textDecoration}
            >
              <IconButton
                label="Explore In-Prep Pathway"
                icon="https://assets.languify.in/images/bookCall_White.svg"
                width="253px"
                bgColor={theme.palette.primary['clr-500']}
                color={theme.palette.shades['clr-white-900']}
              />
            </a>
          )}
        </>
      </Box>
      <Box className={`${classes.imageDown} ${isVisible ? classes.imageDownVisible : ''}`}>
        <img src="https://assets.languify.in/images/hexagonal_dotted_down.png" alt="down" />
      </Box>
    </Box>
  );
};

export default SolutionSection;
