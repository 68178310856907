import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: '364px',
    height: '506px',
    border: '1px solid',
    borderColor: theme.palette.danger['clr-300'],
    borderRadius: '8px',
    backgroundColor: "#F0F5FA",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(5),
    padding: theme.spacing(9),
    zIndex: 1,
    transition: 'transform 0.7s ease, opacity 0.7s ease, translateY 0.7s ease', 
    opacity: 1,
    transform: 'translateY(500px)',
    [theme.breakpoints.down("md")]: {
      width: '350px',
      margin: theme.spacing(0, 2),
      padding: theme.spacing(5),
     },
  },
  visible: {
    opacity: 1,
    transform: 'translateY(0)',
  },
  headBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
    height: '212px',
    [theme.breakpoints.down("sm")]: {
      height:"auto"
     },
  },
  textBox: {
    width: '100%',
    padding: theme.spacing(5),
    backgroundColor: theme.palette.danger['clr-100'],
    height: '200px',
    borderRadius: '4px',
  }
}));

const IssuesCard = ({ title, subText, cardText, isVisible }) => {
  const classes = useStyles();
  const [isCardVisible, setIsCardVisible] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    setIsCardVisible(isVisible);
  }, [isVisible]);

  return (
    <Box ref={cardRef} className={`${classes.cardContainer} ${isCardVisible ? 
      classes.visible : ''}`}>
      <Box className={classes.headBox}>
        <Typography variant='h1-medium' color='neutral.clr-600'>{title}</Typography>
        <Typography variant='h5-medium' color='neutral.clr-800'>{subText}</Typography>
      </Box>
      <Box className={classes.textBox}>
        <Typography variant='h6-medium'>{cardText}</Typography>
      </Box>
    </Box>
  );
}

export default IssuesCard;
