import Session from "../utils/Session";
import Client from "./_client";

const apiUrl = process.env.REACT_APP_API_URL;

export const analyseAnswer = ({
  attemptId,
  questionId,
  timeTaken,
  audioBlob,
  serialNumber,
  template,
  forTNT
}, onEvent) => {
  const formData = new FormData();
  const httpRequest = new XMLHttpRequest();

  formData.append("question", questionId);
  formData.append("attempt", attemptId);
  formData.append("timeTaken", timeTaken);
  formData.append("serialNumber", serialNumber);
  formData.append("template", template);
  formData.append("forTNT", forTNT);
  formData.append("audio", audioBlob);

  httpRequest.upload.addEventListener('progress', (e) => {
    const progress = Math.round((e.loaded / e.total) * 100);
    onEvent('progress', progress);
  });

  httpRequest.onload = (e) => {
    const response = JSON.parse(e?.target?.response || null);

    if (e?.target?.status !== 200) {
      onEvent('error', response?.data);
    } else if (response?.data?.isError) {
      onEvent('analysed', response?.data);
    } else {
      onEvent('analysed', response?.data);
    }
  };
  httpRequest.onabort = (e) => {
    onEvent('error', e);
  };
  httpRequest.onerror = (e) => {
    onEvent('error', e);
  };

  httpRequest.open('POST', `${apiUrl}/analyse/interactive-demo`, true);
  httpRequest.setRequestHeader('userId', Session.userId);
  httpRequest.setRequestHeader('languify-application', 'languify');
  httpRequest.setRequestHeader('languify-access-token', Session.accessToken);
  httpRequest.setRequestHeader(
    "languify-session-id", localStorage.getItem("languifySessionId")
  );
  httpRequest.send(formData);
};

export const skipQuestion = async ({
  questionId,
  attemptId,
  timeTaken,
  transcript,
  serialNumber,
}) => {
  const result = await new Client({
    path: `/analyse/interview/skip`,
    payload: {
      question: questionId,
      attempt: attemptId,
      timeTaken,
      serialNumber,
      transcript,
    }
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to skip the question. Please refresh again."
    );
  }

  return result?.data;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { analyseAnswer, skipQuestion };
