import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const SnackbarContext = React.createContext({
  _open: false,
  _message: "",
  _variant: "",
  _autoHide: true,
  _setOpen: (value) => { },
  _setMessage: (value) => { },
  _setVariant: (value) => { },
  _setAutoHide: (value) => { }
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomSnackbar = ({ onClose, autoHide, ...props }) => {
  return (
    <Snackbar
      open={props.open}
      {...(autoHide ? { autoHideDuration: 3000 } : {})}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      {...props}
    >
      <Alert onClose={onClose} severity={props.variant} sx={{ width: "100%" }}>
        {props.message}
      </Alert>
    </Snackbar>
  );
};


const SnackbarProvider = ({ children }) => {
  const [_open, _setOpen] = React.useState(false);
  const [_message, _setMessage] = React.useState("");
  const [_variant, _setVariant] = React.useState("");
  const [_autoHide, _setAutoHide] = React.useState(true);

  const handleClose = () => {
    _setOpen(false);
    _setAutoHide(true);
    _setMessage("");
  };

  return (
    <SnackbarContext.Provider
      value={
        {
          _open,
          _setOpen,
          _message,
          _setMessage,
          _variant,
          _setVariant,
          _autoHide,
          _setAutoHide,
        }
      }
    >
      <CustomSnackbar
        open={_open}
        message={_message}
        variant={_variant}
        onClose={handleClose}
        autoHide={_autoHide}
      />
      {children}
    </SnackbarContext.Provider>
  );
};

const useSnackbar = () => {
  const { _setOpen, _setMessage, _setVariant, _setAutoHide } = React.useContext(SnackbarContext);

  const showSnackbar = (_message = "", _variant = "success", _autoHide = true) => {
    if (_message) {
      _setVariant(_variant);
      _setMessage(_message);
      _setAutoHide(_autoHide);
      _setOpen(true);
    }
  };

  const hideSnackbar = () => {
    _setOpen(false);
    _setAutoHide(true);
    _setMessage("");
  };

  return { showSnackbar, hideSnackbar };
};

export default CustomSnackbar;

export { SnackbarProvider, useSnackbar };