import React from "react";

import ProductBreakdowns from "components/ProductBreakDowns";

const Steps = [
    {
        label: 'Practice',
        title: 'Practice',
        about: 'Provide unlimited access to curriculum-based verbal assessments to your students',
        video: "https://assets.languify.in/gifs/practice_gif.mp4",
        url: process.env.REACT_APP_GET_IN_TOUCH_TALLY_FORM_URL,
        newPage: true,
        reverse: true,
        discriptions: [
            'Support students with unlimited curriculum-based verbal assessments.',
            'Keep learning fresh and challenging with personalized assessments and tailored learning.',
            'Verbal assessments, available 24/7 to achieve learning outcomes quickly and thoroughly.'
        ]
    },
    {
        label: 'Feedback',
        title: 'Feedback',
        about: 'Give students the 1 - on - 1 attention they deserve through personalized feedback on performance',
        video: "https://assets.languify.in/gifs/feedback_gif.mp4",
        url: process.env.REACT_APP_GET_IN_TOUCH_TALLY_FORM_URL,
        newPage: true,
        discriptions: [
            'Guide student improvements with 20+ personalized nudges',
            'Help students learn in a goal-oriented manner by sharing outstanding ideal responses for comparison.',
            'Get a thorough evaluation of the answer based on 8 critical parameters concerning content quality and delivery.'
        ]
    },
    {
        label: 'Improve',
        title: 'Improve',
        about: 'Help students become master communicators through gamification and micro-learning resources',
        video: "https://assets.languify.in/gifs/improve_gif.mp4",
        url: process.env.REACT_APP_GET_IN_TOUCH_TALLY_FORM_URL,
        newPage: true,
        reverse: true,
        discriptions: [
            'Gamify concept-learning and content delivery with engaging modules',
            '1000+ nuggets of wisdom to improve upon and ace future assessments',
            'Clear scale and proficiency indicators to track improvement'
        ]
    },
];
  
export default function ProductBreakdown({ onExperienceLive }) {

    return (
        <ProductBreakdowns onExperienceLive={onExperienceLive} Steps={Steps}/>
    );
}