import React from "react";
import { Box } from "@mui/material";

import Footer from "./Footer";
import Header from "./Header";

function AppShell({ children, hideHeader,B2C=false}) {
  return (
    <Box
      overflow="hidden"
      minHeight="100vh"
      display="flex"
      flexDirection="column"
    >
      {!hideHeader && <Header isB2c={B2C} />}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        flexGrow={1}
        minHeight={"calc(100vh - 80px)"}
      >
        {children}
      </Box>
      <Footer isB2c={B2C} />
    </Box>
  );
}

export default AppShell;
