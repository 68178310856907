import React from 'react';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/material';
import { useTheme } from '@mui/styles';

import AppShell from '../components/AppShell';
import { PlusTiles } from 'components/Tiles';
import { trackCustomEvent } from "utils";
import { HoverButton } from '../components/CustomButton';

const Data=[
    {
        title:"Students",
        price:"1999",
        features:[
           " Get curated job openings based on your role and experience level from our Job Board",
           "Practice unlimited mock interviews so you can take the real one confidently. ",
           "Get personalized feedback on your mistakes and how to fix them.",
           "Answer real interview questions that your dream companies have asked in the past"
        ],
        button:"Buy Now"
    },
    {
        title:"Business",
        price:"500",
        features:[
            "Improve student placement rates by 20%",
            "Become an AI powered institute and differentiate yourself from competitors",
            "Track each student's progress and design interventions when required",
            "Reduce operational costs by 50% by avoiding unnecessary manual involvement. "
        ],
        button:"Book a Call"
    },
]

const useStyles=makeStyles((theme)=>({
    container:{
        position:"relative",
        width:"100%",
    },
    background:{
        background: "linear-gradient(278.27deg, #4B6CB7 0%, #002759 112.69%)",
        height:400,
        width:"100%",
        position:"absolute",
        overflow:"hidden",
        top:0,
        right:0,
        left:0
    },
    backgroundImgCenter:{
        position:"absolute",
        animationName:`$enterBottom`,
        animationDuration:"500ms",
        animationTimingFunction:"ease",
        [theme.breakpoints.down('md')]:{
            display:"none"
        }
    },
    backgroundImgLeft:{
        position:"absolute",
        left:0,
        top:40,
        animationName:`$enterLeft`,
        animationDuration:"500ms",
        animationTimingFunction:"ease",
        [theme.breakpoints.down('lg')]:{
            left:-100,
            top:80,
            height:289
        }
    },
    backgroundImgRight:{
        position:"absolute",
        right:0,
        animationName:`$enterRight`,
        animationDuration:"500ms",
        animationTimingFunction:"ease",
        [theme.breakpoints.down('lg')]:{
            right:-80
        },
        [theme.breakpoints.down('sm')]:{
            right:-80,
            height:140,
            top:40
        }
    },
    imgCenter:{
        position:"relative",
        width:"90%",
        margin:"auto",
        display:"flex",
        justifyContent:"center"
    },
    box:{
        position:"relative",
        zIndex:2,
        background:`url("https://assets.languify.in/images/gridFrame.png")`,
        backgroundColor:theme.palette.shades['clr-white-900'],
        padding:theme.spacing(12),
        borderRadius:12,
        display:"flex",
        flexDirection:"column",
        gap:theme.spacing(14),
        boxShadow:" 0px 2px 8px 0px #003E8C40",
        margin:theme.spacing(64,37.5,6,37.5),
        overflow:"hidden",
        width:"auto",
        [theme.breakpoints.between('md','lg')]:{
            padding:theme.spacing(12,15),
            margin:theme.spacing(54,15,6,15),
            gap:theme.spacing(10),
        },
        [theme.breakpoints.down('md')]:{
            padding:theme.spacing(8,10),
            margin:theme.spacing(44,10,6,10),
            gap:theme.spacing(8),
          },
        [theme.breakpoints.down("sm")]:{
            padding:theme.spacing(8,5),
            marginBottom:0,
            borderRadius:0,
        },
    },
    cardContainer:{
        display:"flex",
        width:"100%",
        justifyContent:"space-around",
        flexWrap:"wrap",
        gap:theme.spacing(8),
        animationName:`$enterBottom`,
        animationDuration:"500ms",
        animationTimingFunction:"ease",
        marginBottom: theme.spacing(5)
    },
    card:{
        background:theme.palette.secondary['clr-50'],
        boxShadow: "0px 0px 24px 0px #D2F3FA",
        border:"1px solid",
        borderColor:theme.palette.secondary.main,
        width:397,
        height:667,
        borderRadius:24,
        padding:theme.spacing(7,5,24,5),
        display:"flex",
        flexDirection:"column",
        position:"relative",
        gap:theme.spacing(6),
        [theme.breakpoints.down('md')]:{
            width:"400px",
        },
        [theme.breakpoints.down('sm')]:{
            width:"100%",
            height:580
        },
        [theme.breakpoints.down('400')]:{
            width:"100%",
            height:650
        }
    },
    title:{
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        gap:theme.spacing(4)
    },
    titleDesc:{
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
    },
    heading:{
        background: 'linear-gradient(278.27deg, #4B6CB7 0%, #002759 112.69%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundClip: 'text',
        textFillColor: 'transparent',
        textAlign:"center",
        animationName:`$enterBottom`,
        animationDuration:"500ms",
        animationTimingFunction:"ease",
        [theme.breakpoints.down('sm')]:{
          marginBottom: theme.spacing(3),
          '&>*': {
            fontSize: '33px !important'
          }
        }
    },
    description:{
        display:"flex",
        gap:theme.spacing(1),
        alignItems:"start",
    },
    icon:{
        paddingTop:theme.spacing(1)
    },
    featureBox:{
        display:"flex",
        flexDirection:"column",
        gap:theme.spacing(6),
        paddingBottom:theme.spacing(8)
    },
    chip:{
        position:"absolute",
        bottom:60,
        width:"90%",
        margin:"auto",
        display:"flex",
        justifyContent:"center",
        [theme.breakpoints.down('sm')]:{
            bottom:40
        }
    },
    tilesLeft:{
        position:"absolute",
        top:-20,
        zIndex:-1,
        left:-40,
        [theme.breakpoints.up('lg')]:{
            top:-35,
        },
        [theme.breakpoints.between('md','lg')]:{
            top:-31,
        },
        [theme.breakpoints.between('sm','md')]:{
            top:-31,
            left:-32
        },
    },
    tilesRight:{
        position:"absolute",
        bottom:-20,
        zIndex:-1,
        right:-40,
        [theme.breakpoints.up('lg')]:{
            bottom:-38
        },
        [theme.breakpoints.between('md','lg')]:{
            bottom:-40,
        },
    },
    connectButton:{
        background: "linear-gradient(278.27deg, #4B6CB7 0%, #002759 112.69%)",
        border:"1px solid",
        borderColor:theme.palette.primary.main,
        borderRadius:8,
        cursor:"pointer",
        padding:theme.spacing(3,6),
        display:"flex",
        justifyContent:"center",
        width:"80%",
    },
    '@keyframes enterTop': {
        'from': {
            transform: 'translateY(-10%)',
            opacity: 0,
        },
        'to': {
            transform: 'translateY(0%)',
            opacity: 1,
        },
    },
    '@keyframes enterBottom': {
        'from': {
            transform: 'translateY(10%)',
            opacity: 0,
        },
        'to': {
            transform: 'translateY(0%)',
            opacity: 1,
        },
    },
    '@keyframes enterRight': {
        'from': {
            transform: 'translateX(50px)',
            opacity: 0,
        },
        'to': {
            transform: 'translateX(0%)',
            opacity: 1,
        },
    },
    '@keyframes enterLeft': {
        'from': {
            transform: 'translateX(-50px)',
            opacity: 0,
        },
        'to': {
            transform: 'translateX(0%)',
            opacity: 1,
        },
    },
}))

const Card=({value,index})=>{
    const classes=useStyles();
    const theme=useTheme();

    const handleClick=()=>{
        trackCustomEvent('get_in_touch_inprep');
        window.open(process.env.REACT_APP_GET_IN_TOUCH_TALLY_FORM_URL, '_blank');
    }

    return(
        <Box key={index} className={classes.card}>
            <Box className={index === 0 ? classes.tilesLeft : classes.tilesRight}>
                <PlusTiles variant='body01-medium' color={theme.palette.shades['clr-black-900']} />
            </Box>
            <Box className={classes.title}>
                <Typography variant="h2-medium" color="#1961BF">
                    {value.title}
                </Typography>
            </Box>
            <Box className={classes.titleDesc}>
                <Typography variant="h4-medium" color="primary.main">
                    INR {value.price}
                </Typography>
                <Typography variant='body01-bold' color="primary.main">
                    (per student)
                </Typography>
            </Box>
            <Box className={classes.featureBox}>
                {value.features.map((feature, featureIndex) => (
                    <Box className={classes.description}>
                        <img 
                            className={classes.icon} 
                            src="https://assets.languify.in/images/Icon.svg"
                            alt="Icon"
                        />
                        <Typography key={featureIndex} variant="h6-medium">
                            {feature}
                        </Typography>
                    </Box>
                ))}
            </Box>
            <Box className={classes.chip}>
                <HoverButton 
                    label={value.button}
                    onClick={handleClick}
                />
            </Box>
        </Box>
    )
}

function Pricing() {
    const classes=useStyles();

    return (
        <AppShell>
            <Box className={classes.container}>
                <Box className={classes.background}>
                    <img 
                        className={classes.backgroundImgLeft}
                        src="https://assets.languify.in/images/Pricing_circletiles.png"
                        alt="Tiles"
                    /> 
                    <img 
                        className={classes.backgroundImgRight}
                        src="https://assets.languify.in/images/Pricing_circle.png"
                        alt="Tiles"
                    />
                    <Box className={classes.imgCenter}>
                        <img 
                            className={classes.backgroundImgCenter}
                            src="https://assets.languify.in/images/Pricing_tiles.png"
                            alt="Tiles"
                        />
                    </Box>
                </Box>
                <Box className={classes.box}>
                    <Box className={classes.heading}>
                        <Typography variant='h1-semiBold'>
                            Simple transparent pricing
                        </Typography>
                    </Box>
                    <Box className={classes.cardContainer}>
                        {Data.map((value,index)=>(
                            <Card value={value} key={index} index={index}/>
                        ))}
                    </Box>
                </Box>
            </Box>
        </AppShell>
  );
}

export default Pricing;
